import React, { useState } from 'react';
import styled from 'styled-components';
import { PageContainer } from 'src/components/layout/page-containers';
import {
  BroadcastWizard,
  BroadcastWizardStep,
} from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import { BroadcastWizardHints } from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard-hints';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';

const StyledPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const BroadcastDetailsPage = () => {
  useAddBreadcrumb('');

  const [wizardStep, setWizardStep] = useState<BroadcastWizardStep>(
    BroadcastWizardStep.AUDIENCE,
  );

  return (
    <StyledPageContainer data-lgg-id="broadcast-detail-page">
      <BroadcastWizard step={wizardStep} setWizardStep={setWizardStep} />
      <BroadcastWizardHints step={wizardStep} />
    </StyledPageContainer>
  );
};
