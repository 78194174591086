import React, { memo, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import qs from 'qs';
import * as zod from 'zod';
import { CompanyUsersSelect } from 'src/components/domain/users/components/company-users-select';
import { ReactFilters } from 'src/components/filters/react-filters';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { StyledTableFiltersForm } from 'src/components/general/table-helpers';
import { useAuthorization } from 'src/hooks/use-authorization';

type EventType = 'SCHEDULE' | 'TASK';

export type CalendarFiltersFormValues = {
  eventType?: EventType[] | undefined;
  owner?: undefined | string[];
};

export const defaultCalendarFilters = {
  eventType: undefined,
  owner: undefined,
};

const useSubmitHandler = <T,>(params: { onClose: VoidFunction }) => {
  const location = useLocation();
  const history = useHistory();
  const { onClose } = params;

  return (formValues: T) => {
    const queryString = qs.stringify(formValues, {
      arrayFormat: 'comma',
      encodeValuesOnly: true,
      encode: false,
    });
    const newUrl = `${location.pathname}?${queryString}`;
    onClose();
    history.push(newUrl);
  };
};

export const CalendarFilters = memo<{
  onClose: VoidFunction;
  visible: boolean;
  filters: CalendarFiltersFormValues;
}>(({ visible, onClose, filters }) => {
  const { t } = useTranslation(['common', 'calendar']);
  const { hasPermission } = useAuthorization();
  const hasAcessViewAll =
    hasPermission('lead.access.view.all') &&
    hasPermission('lead.interaction.content.view.all'); // Agent plus can not see owner filter
  const history = useHistory();
  const location = useLocation();

  const schema = useMemo(
    () =>
      zod.object({
        eventType: zod
          .array(zod.union([zod.literal('SCHEDULE'), zod.literal('TASK')]))
          .optional(),
        owner: zod.array(zod.string()).optional(),
      }),
    [],
  );

  const form = useForm<CalendarFiltersFormValues>({
    resolver: zodResolver(schema),
    defaultValues: defaultCalendarFilters,
  });

  const { control, handleSubmit, setValue, reset } = form;

  useEffect(() => {
    if (filters) {
      reset(filters);
    }
  }, [filters, reset]);

  const submitHandler = useSubmitHandler({ onClose });
  const eventTypeOptions: SelectOption<string>[] = [
    {
      label: t('calendar:filters.eventType.options.schedule'),
      value: 'SCHEDULE',
    },
    {
      label: t('calendar:filters.eventType.options.task'),
      value: 'TASK',
    },
  ];

  return (
    <ReactFilters
      form={form}
      onSave={() => {
        void handleSubmit(submitHandler)();
      }}
      onReset={() => {
        onClose();
        reset(defaultCalendarFilters);
        history.push(location.pathname);
      }}
      filters={filters}
      visible={visible}
      onClose={onClose}
      testId="calendar-filters"
    >
      <StyledTableFiltersForm>
        <Controller
          control={control}
          name="eventType"
          render={({ field }) => {
            const selectedOption =
              eventTypeOptions.filter(({ value: optionValue }) =>
                field.value?.includes(optionValue as EventType),
              ) ?? null;

            return (
              <Select
                name="calendar-filters-event-type"
                value={selectedOption}
                options={eventTypeOptions}
                isSearchable={false}
                isMulti
                label={t('calendar:filters.eventType.label')}
                placeholder={t('calendar:filters.eventType.placeholder')}
                onChange={(option) =>
                  setValue(
                    field.name,
                    option?.map(({ value }) => value as EventType),
                  )
                }
              />
            );
          }}
        />
        {hasAcessViewAll && (
          <Controller
            control={control}
            name="owner"
            render={({ field }) => {
              return (
                <CompanyUsersSelect
                  name="calendar-filters-field-owner"
                  selectedUsersIds={
                    field.value ? field.value.map((val) => Number(val)) : []
                  }
                  isMulti
                  label={t('calendar:filters.owner.label')}
                  placeholder={t('calendar:filters.owner.placeholder')}
                  onChange={(option) =>
                    setValue(
                      field.name,
                      option?.map(({ value }) => value.toString()),
                    )
                  }
                />
              );
            }}
          />
        )}
      </StyledTableFiltersForm>
    </ReactFilters>
  );
});
