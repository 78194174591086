import { createContext, FC, ReactNode, useContext } from 'react';
import { useService } from 'react-service-locator';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Query, User } from '@lgg/isomorphic/types/__generated__/graphql';
import { ErrorService } from 'src/services/error.service';
import { SessionService } from 'src/services/session.service';

const FETCH_ME_QUERY = gql`
  query GetMe {
    me {
      user {
        avatar {
          initials
          avatarUrl
        }
        id
        username
        email
        firstName
        lastName
        role {
          name
        }
        institution {
          id
          name
          type
        }
        language
      }
    }
  }
`;

type AuthContextValue = {
  user: User | null;
};

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const sessionService = useService(SessionService, ({ state }) => [state.sessionData]);

  const [fetchMe] = useLazyQuery<Pick<Query, 'me'>>(FETCH_ME_QUERY);

  sessionService.suspendAndInitialize(fetchMe);

  const errorService = useService(ErrorService);
  errorService.suspendAndInitialize();

  const { data } = useQuery<Pick<Query, 'me'>>(FETCH_ME_QUERY, {
    fetchPolicy: 'cache-only',
  });

  return (
    <AuthContext.Provider
      value={{
        user: data?.me?.user || null,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = <T extends boolean = true>(authRequired: T = true as T) => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const { user } = context;

  if (authRequired && !user) {
    throw new Error('This component requires an authenticated user.');
  }

  return { user: user as T extends true ? User : User | null };
};
