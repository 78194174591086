import React, { memo } from 'react';
import { Trans } from 'react-i18next';
import { match, P } from 'ts-pattern';
import { ContactInteractionWhatsapp } from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionWhatsappExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import { TextItem } from 'src/components/pages/conversations/components/contact-interactions/items/text-item';
import { sanitizeWhatsappMessage } from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/whatsapp-subitems/shared';
import { WhatsappTemplateItem } from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/whatsapp-subitems/whatsapp-template-item';

const WhatsappMessageItem = (props: {
  contactInteraction: ContactInteractionWhatsapp;
  conversationId: string;
}) => {
  const { contactInteraction, conversationId } = props;

  const buildManualInteractionTitle = () => {
    const transComponents = { strong: <strong /> };

    return matchContactInteractionWhatsappExhaustive(contactInteraction, {
      outbound: () => (
        <Trans
          i18nKey="conversations:contactInteractionBubble.whatsapp.outbound"
          components={transComponents}
        />
      ),
      inboundWithParticipatingEntity: ({ participatingEntity }) => (
        <Trans
          i18nKey="conversations:contactInteractionBubble.whatsapp.inboundWithParticipatingEntity"
          components={transComponents}
          values={{ participatingEntityLabel: participatingEntity.label }}
        />
      ),
      inboundWithoutParticipatingEntity: () => (
        <Trans
          i18nKey="conversations:contactInteractionBubble.whatsapp.inboundWithoutParticipatingEntity"
          components={transComponents}
        />
      ),
    });
  };

  return (
    <TextItem
      testId="contact-interaction-whatsapp"
      contactInteraction={contactInteraction}
      conversationId={conversationId}
      titleBuilder={buildManualInteractionTitle}
      messageSanitizer={(message) => sanitizeWhatsappMessage(message)}
    />
  );
};

type WhatsappItemProps = {
  contactInteraction: ContactInteractionWhatsapp;
  conversationId: string;
};

export const WhatsappItem = memo<WhatsappItemProps>(
  ({ contactInteraction, conversationId }) => {
    return match(contactInteraction)
      .with(
        {
          template: P.not(P.nullish),
        },
        (contactInteraction) => (
          <WhatsappTemplateItem
            contactInteraction={contactInteraction}
            conversationId={conversationId}
          />
        ),
      )
      .otherwise(() => (
        <WhatsappMessageItem
          contactInteraction={contactInteraction}
          conversationId={conversationId}
        />
      ));
  },
);
