import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'antd/lib/dropdown';
import c from 'classnames';
import { up } from 'styled-breakpoints';
import styled, { DefaultTheme, createGlobalStyle, css } from 'styled-components';
import {
  ContactSource,
  Department,
  Campaign,
  ContactInteractionDirection,
  RegistrationType,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  ContactInteractionDetailsContextMenu,
  ContactInteractionDetailItem,
  ContactInteractionDetailsMenuTitle,
} from 'src/components/domain/contact-interaction/contact-interaction-detail-item';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import { LongPressWrapper } from 'src/components/general/long-press-wrapper';
import { FlexColumn } from 'src/components/layout/flex-column';
import { ChangeContactInteractionStatusModal } from 'src/components/pages/conversations/components/contact-interactions/dev-tools/change-contact-interaction-status-modal';
import { InternalNoteType } from 'src/components/pages/conversations/components/contact-interactions/items/shared';
import { BottomDrawer } from 'src/components/pages/conversations/components/drawer/bottom-drawer';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useUrls } from 'src/hooks/use-urls';
import { overlayShapeStyles } from 'src/theme/sub-themes/overlays-theme';
import {
  copyTextToClipboard,
  copyUrlToClipboard,
} from 'src/utils/copy-to-clipboard-helpers';

const CaretIcon = styled(Icon)`
  position: absolute;
  pointer-events: auto;
  top: 0;
  padding: 10px;
  height: 32px;
  width: 32px;
  display: flex;
  opacity: 0;
  right: -20px;
  justify-content: end;
  border-radius: inherit;
  // This value is 3 to be on top of attachments overlay (currently 2)
  z-index: 3;
  transition: all 0.3s ease;
  background: transparent;

  &.show-caret {
    opacity: 1;
    right: 0;
  }

  &.open {
    right: 0;
    opacity: 1;
    transform: rotate(-180deg);
  }

  svg {
    cursor: pointer;
    width: 12px;
    transform: rotate(180deg);

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const ContextMenuStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  .ant-dropdown.contact-interaction-details-dropdown {
    ${overlayShapeStyles};
    overflow: hidden;
  }
`;

const ContactInteractionDetailFooter = styled.div`
  padding: 10px 10px 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.porcelain};

  ${up('md')} {
    margin-top: 5px;
    padding: 5px 4px 0;
  }
`;

const contactInteractionDetailFooterOptionBaseStyle = css`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 15px;
  height: 38px;
  line-height: normal;
  padding: 0 10px;
  width: 100%;
  color: ${({ theme }) => theme.colors.flint};

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${up('md')} {
    height: 32px;
    padding: 8px 10px;
    font-size: 12px;

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  .lgg-icon {
    margin-right: 20px;

    ${up('md')} {
      margin-right: 10px;
    }

    svg {
      height: 18px;
      width: 18px;

      ${up('md')} {
        height: 16px;
        width: 16px;
      }

      path {
        fill: ${({ theme }) => theme.colors.smalt};
      }
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.porcelain};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.flint};

    svg path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }
`;

export const ContactInteractionDetailFooterOption = styled.div`
  ${contactInteractionDetailFooterOptionBaseStyle};
`;

const ContactInteractionDetailFooterLinkOption = styled.a`
  ${contactInteractionDetailFooterOptionBaseStyle};

  &:active,
  &:visited {
    color: ${({ theme }) => theme.colors.flint};
  }
`;

const ContactInteractionDetailsContainer = styled.div`
  padding-bottom: 5px;

  ${up('md')} {
    padding-bottom: 0;
  }
`;

const ContactInteractionDetailBody = styled(FlexColumn)`
  padding: 15px 10px;

  ${up('md')} {
    padding: 0 5px;
  }
`;

const ContactInteractionDetailsWrapper = styled.span`
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
`;

const AddDepartmentOption = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gogo};
  cursor: pointer;
`;

const CaretContainer = styled.span<{ visible: boolean }>`
  right: 0;
  pointer-events: none;
  overflow: hidden;
  width: 54px;
  height: 42px;
  position: absolute;
  display: flex;
  top: 0;
  border-radius: inherit;
  transition: all 0.2s ease-out;
  border-top-right-radius: 8px;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  z-index: 3;

  &.clear {
    background: transparent;
  }

  &.blue {
    background: linear-gradient(
      37deg,
      rgba(209, 239, 245, 0) 44%,
      rgba(209, 239, 245, 0.8) 60%,
      rgba(209, 239, 245, 0.95) 80%,
      rgba(209, 239, 245, 1) 100%
    );
  }

  &.white {
    background: linear-gradient(
      37deg,
      rgba(255, 255, 255, 0) 44%,
      rgba(255, 255, 255, 0.8) 60%,
      rgba(255, 255, 255, 0.95) 80%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  &.dark {
    background: linear-gradient(
      35deg,
      rgba(48, 68, 87, 0) 47%,
      rgba(48, 68, 87, 0.5) 100%
    );

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  &.yellow {
    background: linear-gradient(
      217deg,
      ${({ theme }) => theme.colors.secondaryGold30} 51%,
      #ffe9b100 9%
    );
  }
`;

export type ContactInteractionDetailsPopoverData = {
  department?: Department | null;
  campaign?: Campaign | null;
  source?: ContactSource | null;
  interactionId: string;
  conversationId: string;
  message?: string | null;
  resourceAddress?: string | null;
  contactAddress?: string | null;
};

export enum ContactInteractionDetailsCaretVariant {
  WHITE = 'white',
  BLUE = 'blue',
  YELLOW = 'yellow',
  DARK = 'dark',
  CLEAR = 'clear',
}

type ContactInteractionDetailsProps = {
  registrationType: RegistrationType;
  isCall?: boolean;
  noteType?: InternalNoteType;
  children: React.ReactNode;
  details: ContactInteractionDetailsPopoverData | null;
  visible: boolean;
  direction: ContactInteractionDirection;
  setVisibility: (isVisible: boolean) => void;
  onClick?: VoidFunction;
  onClose?: VoidFunction;
  caretVariantOverride?: ContactInteractionDetailsCaretVariant;
  contactInteractionType?: string;
  contactInteractionLogId?: string;
};

export const ContactInteractionDetails = ({
  registrationType,
  children,
  details,
  isCall = false,
  noteType,
  visible,
  direction,
  setVisibility,
  onClick = () => {},
  onClose,
  caretVariantOverride,
  contactInteractionType,
  contactInteractionLogId,
}: ContactInteractionDetailsProps) => {
  const { t } = useTranslation(['conversations']);
  const { hasPermission } = useAuthorization();
  const isAdmin = hasPermission('system.admin');
  const breakpointUpMd = useBreakpoint(up('md'));
  const {
    getLegacyDebugCallUrl,
    getLegacyDebugNotificationUrl,
    getInternalNoteUrl,
    getConversationContactInteractionUrl,
  } = useUrls();
  const { getFeatureFlag } = useAuthorization();
  const [showCaret, setShowCaret] = useState(false);
  const showNotification = useShowNotification();
  const handleCopySuccess = useCallback(
    (notificationTitle: string) => {
      setVisibility(false);
      showNotification({
        title: notificationTitle,
        type: 'success',
      });
    },
    [setVisibility, showNotification],
  );

  const isNote = useMemo(() => !!noteType, [noteType]);

  const getCaretType: () => ContactInteractionDetailsCaretVariant = () => {
    if (isNote) {
      return ContactInteractionDetailsCaretVariant.YELLOW;
    }

    if (direction === 'INBOUND') {
      return ContactInteractionDetailsCaretVariant.WHITE;
    }

    if (registrationType === 'AUTOMATIC' || direction === 'OUTBOUND') {
      return ContactInteractionDetailsCaretVariant.BLUE;
    }

    return ContactInteractionDetailsCaretVariant.CLEAR;
  };

  if (!details) {
    return <>{children}</>;
  }

  const {
    interactionId,
    department,
    campaign,
    source,
    message,
    conversationId,
    contactAddress,
    resourceAddress,
  } = details;

  const contactInteractionDetailsContent = (
    <ContactInteractionDetailsContainer>
      <ContactInteractionDetailBody>
        {/* TODO: Change this condition to just department to enable the 'select department' option  */}
        {department?.name && (
          <ContactInteractionDetailItem
            title={t(
              'conversations:contactInteractionPopover.items.department.itemTitle',
            )}
            value={
              department.name || (
                <AddDepartmentOption>
                  {t(
                    'conversations:contactInteractionPopover.items.department.selectDepartment',
                  )}
                </AddDepartmentOption>
              )
            }
          />
        )}
        {campaign && (
          <ContactInteractionDetailItem
            title={t('conversations:contactInteractionPopover.items.campaign')}
            value={campaign.name}
          />
        )}
        {source && (
          <ContactInteractionDetailItem
            title={t('conversations:contactInteractionPopover.items.source')}
            value={source.name}
          />
        )}
        <ContactInteractionDetailItem
          title={t('conversations:contactInteractionPopover.items.id')}
          value={interactionId}
        />
        {resourceAddress && (
          <ContactInteractionDetailItem
            data-lgg-id="contact-interaction-details-resource-address"
            title={t('conversations:contactInteractionPopover.items.resource')}
            value={resourceAddress}
          />
        )}
        {contactAddress && (
          <ContactInteractionDetailItem
            data-lgg-id="contact-interaction-details-copy-contact-address"
            title={t('conversations:contactInteractionPopover.items.destination')}
            value={contactAddress}
          />
        )}
      </ContactInteractionDetailBody>
      <ContactInteractionDetailFooter>
        <ContactInteractionDetailFooterOption
          data-lgg-id="contact-interaction-details-copy-link-to-message-button"
          onClick={() => {
            const urlOptions = { includeHost: true };
            const linkToMessage = noteType
              ? getInternalNoteUrl({
                  conversationId: conversationId,
                  noteId: interactionId,
                  options: urlOptions,
                  noteType,
                })
              : getConversationContactInteractionUrl({
                  conversationId,
                  contactInteractionId: interactionId,
                  options: urlOptions,
                });

            copyUrlToClipboard(linkToMessage);
            handleCopySuccess(
              t(
                'conversations:contactInteractionPopover.items.copyLinkToMessage.message',
              ),
            );
          }}
        >
          <Icon type="link" />
          {t('conversations:contactInteractionPopover.items.copyLinkToMessage.label')}
        </ContactInteractionDetailFooterOption>
        {message && (
          <ContactInteractionDetailFooterOption
            data-lgg-id="contact-interaction-details-copy-text-button"
            onClick={() => {
              copyTextToClipboard(message);
              handleCopySuccess(
                t('conversations:contactInteractionPopover.items.copyText.message'),
              );
            }}
          >
            <Icon type="copy" />
            {t('conversations:contactInteractionPopover.items.copyText.label')}
          </ContactInteractionDetailFooterOption>
        )}
        {isAdmin && registrationType === 'AUTOMATIC' && (
          <>
            {isCall && (
              <ContactInteractionDetailFooterLinkOption
                data-lgg-id="contact-interaction-details-debug-call-button"
                target="_blank"
                href={getLegacyDebugCallUrl(interactionId)}
              >
                <Icon type="debug" />
                {t('conversations:contactInteractionPopover.items.debugCall')}
              </ContactInteractionDetailFooterLinkOption>
            )}
            <ContactInteractionDetailFooterLinkOption
              data-lgg-id="contact-interaction-details-debug-notification-button"
              href={getLegacyDebugNotificationUrl(interactionId)}
              target="_blank"
            >
              <Icon type="debug" />
              {t('conversations:contactInteractionPopover.items.debugNotification')}
            </ContactInteractionDetailFooterLinkOption>
          </>
        )}
        {getFeatureFlag('testing_toolkit') &&
          contactInteractionLogId &&
          contactInteractionType === 'ContactInteractionWhatsapp' && (
            <ChangeContactInteractionStatusModal
              contactInteractionLogId={contactInteractionLogId}
              onClick={() => setVisibility(false)}
            />
          )}
      </ContactInteractionDetailFooter>
    </ContactInteractionDetailsContainer>
  );

  return breakpointUpMd ? (
    <ContactInteractionDetailsWrapper
      onClick={onClick}
      onMouseEnter={() => setShowCaret(true)}
      onMouseLeave={() => setShowCaret(false)}
    >
      <>
        <Dropdown
          onVisibleChange={(isVisible) => {
            if (onClose && !isVisible) {
              onClose();
            }

            setVisibility(isVisible);
          }}
          getPopupContainer={(element) => element.parentElement ?? document.body}
          visible={visible}
          overlayClassName="contact-interaction-details-dropdown"
          overlay={
            <ContactInteractionDetailsContextMenu data-lgg-id="contact-interaction-details-popover">
              <ContactInteractionDetailsMenuTitle>
                {t(
                  isNote
                    ? 'conversations:contactInteractionPopover.title.notes'
                    : 'conversations:contactInteractionPopover.title.contactInteractions',
                )}
              </ContactInteractionDetailsMenuTitle>
              {contactInteractionDetailsContent}
            </ContactInteractionDetailsContextMenu>
          }
          placement={direction === 'INBOUND' ? 'bottomLeft' : 'bottomRight'}
          trigger={['click']}
          align={{
            offset: [10, 0],
          }}
        >
          <CaretContainer
            visible={showCaret}
            className={caretVariantOverride ?? getCaretType()}
          >
            <CaretIcon
              lggTestId="contact-interaction-details-trigger"
              type="arrowup"
              className={c({
                'show-caret': showCaret,
                open: visible,
              })}
              onClick={(e) => {
                e?.stopPropagation();
                setVisibility(!visible);
              }}
            />
          </CaretContainer>
        </Dropdown>
        {children}
        <ContextMenuStyle />
      </>
    </ContactInteractionDetailsWrapper>
  ) : (
    <>
      <ContactInteractionDetailsWrapper>
        <LongPressWrapper
          onClick={onClick}
          onLongPress={() => {
            setVisibility(true);
          }}
        >
          {children}
        </LongPressWrapper>
      </ContactInteractionDetailsWrapper>
      <BottomDrawer
        // This was defined as 1002 to be greater than attachment preview's z-index (currently 1001)
        zIndex={1002}
        contentPadding={0}
        destroyOnClose
        onClose={() => {
          onClose?.();
          setVisibility(false);
        }}
        title={t(
          isNote
            ? 'conversations:contactInteractionPopover.title.notes'
            : 'conversations:contactInteractionPopover.title.contactInteractions',
        )}
        visible={visible}
      >
        {contactInteractionDetailsContent}
      </BottomDrawer>
    </>
  );
};
