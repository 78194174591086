import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import {
  Contact,
  ContactTimelineUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useContactTimelineDetails } from 'src/components/domain/contacts/hooks/use-contact-timeline-details';
import { Tooltip } from 'src/components/general/display/tooltip';
import { Icon } from 'src/components/general/icon';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const StyledContactTimelineItem = styled.div`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.2px;
  padding-top: 10px;
  text-align: center;

  a,
  span.link {
    color: ${({ theme }) => theme.colors.gogo};
    cursor: pointer;
    display: inline;
    font-family: ${({ theme }) => theme.font.medium};
  }

  span {
    color: ${({ theme }) => theme.colors.smalt};
  }
`;

const StyledIcon = styled(Icon)<{ color: ColorPaletteItem }>`
  top: 0;
  margin-right: 4px;

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme, color }) => theme.colors[color]};
    }
  }
`;

const StyledText = styled.div`
  display: inline;
`;

type ContactTimelineItemProps = {
  contactTimeline: ContactTimelineUnion;
  contact: Contact;
};

export const ContactTimelineItem = memo<ContactTimelineItemProps>(
  ({ contactTimeline, contact }) => {
    const breakpointUpMd = useBreakpoint(up('md'));
    const { iconProps, text, formattedDate } = useContactTimelineDetails(
      contactTimeline,
      contact,
    );

    return (
      <StyledContactTimelineItem data-lgg-id="contact-timeline-item">
        {iconProps && <StyledIcon {...iconProps} data-lgg-id="icon" />}
        {breakpointUpMd ? (
          <Tooltip placement="top" title={formattedDate}>
            <StyledText data-lgg-id="text">{text}</StyledText>
          </Tooltip>
        ) : (
          <StyledText data-lgg-id="text">
            {text} - {formattedDate}
          </StyledText>
        )}
      </StyledContactTimelineItem>
    );
  },
);
