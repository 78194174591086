import { gql, useQuery } from '@apollo/client';
import {
  BroadcastAvailableContactsForChannelSmsOutput,
  ContactWhereInput,
  Query,
  QueryBroadcastAvailableContactsForChannelSmsArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

const GET_BROADCAST_AVAILABLE_CONTACTS_FOR_CHANNEL_SMS = gql`
  query BroadcastAvailableContactsForChannelSms(
    $resourceId: Int!
    $where: ContactWhereInput
  ) {
    broadcastAvailableContactsForChannelSms(resourceId: $resourceId, where: $where) {
      contactsMatchingCriteria
      contactsWithoutPhoneNumber
      contactsWithOptOut
      contactsAvailable
    }
  }
`;

export type UseBroadcastAvailableContactsForChannelSmsReturn = {
  loading: boolean;
  summary: BroadcastAvailableContactsForChannelSmsOutput | null;
};

export const useBroadcastAvailableContactsForChannelSms = (
  resourceId?: number,
  where?: ContactWhereInput,
): UseBroadcastAvailableContactsForChannelSmsReturn => {
  const handleGraphQLError = useHandleGraphQLError();
  const { data, loading } = useQuery<
    Pick<Query, 'broadcastAvailableContactsForChannelSms'>,
    Partial<QueryBroadcastAvailableContactsForChannelSmsArgs>
  >(GET_BROADCAST_AVAILABLE_CONTACTS_FOR_CHANNEL_SMS, {
    skip: !resourceId,
    variables: {
      resourceId,
      where,
    },
    onError: handleGraphQLError,
  });

  if (!data?.broadcastAvailableContactsForChannelSms) {
    return {
      loading,
      summary: null,
    };
  }

  return {
    loading,
    summary: data.broadcastAvailableContactsForChannelSms,
  };
};
