import React, { memo, useMemo } from 'react';
import { getI18n } from 'react-i18next';
import { useService } from 'react-service-locator';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  FieldPolicy,
  ApolloLink,
  from,
  FieldFunctionOptions,
  gql,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { TExistingRelay } from '@apollo/client/utilities/policies/pagination';
import { SentryLink } from 'apollo-link-sentry';
import qs from 'qs';
import { AppStatusService, BuildInfo } from 'src/services/app-status.service';
import { apiUrl, requestIdentifyingHeaders } from 'src/services/http/helpers';
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEY_API_TOKEN,
} from 'src/utils/local-storage';

type KeyArgs = FieldPolicy['keyArgs'];

const makeEmptyData = (): TExistingRelay<any> & { totalCount: number } => {
  return {
    edges: [],
    totalCount: 0,
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  };
};

const getPaginationTypeFromOptions = (options: FieldFunctionOptions) => {
  const connectionKeyArg = options.field?.directives
    ?.find((directive) => directive.name.value === 'connection')
    ?.arguments?.find((arg) => arg.name.value === 'key');

  if (connectionKeyArg && 'value' in connectionKeyArg.value) {
    return connectionKeyArg.value.value;
  }

  return null;
};

type EdgeWithNodeAndRef = {
  node: {
    __ref: string;
  };
  cursor: string;
};

const isEdgeWithNodeAndRef = (edge: any): edge is EdgeWithNodeAndRef =>
  'node' in edge && '__ref' in edge.node;

const mapEdgesToRefs = (edges: any[] | null | undefined) =>
  (edges ?? [])
    .map((edge) => (isEdgeWithNodeAndRef(edge) ? edge.node.__ref : false))
    .filter(Boolean) as string[];

const infiniteScrollPaginationMerge = ({
  existing,
  incoming,
  options,
  singleRecordPredicate,
  filterExistingNodePredicate,
  multipleRecordPredicate,
  mapArgsIdsToCacheKeys,
}) => {
  const { args } = options;

  // Fetching one record scenario:
  //
  // If the query was supposed to fetch a single record, we need to do some special
  // handling to not break the cache
  if (singleRecordPredicate(args)) {
    let result = makeEmptyData();
    const hasEdge = incoming?.edges?.length;

    // If we get the record, we need to check if it is an existing one or fresh new, using the cache ref
    if (hasEdge) {
      const newEdge = incoming.edges[0];
      const existingEdges = existing?.edges || [];
      const incomingRefs = mapEdgesToRefs([newEdge]);
      const previousEdge = existingEdges.find(
        (edge) => isEdgeWithNodeAndRef(edge) && incomingRefs.includes(edge.node.__ref),
      );

      let uniqueExisting = { ...existing };

      // If the record was found in the cache, we remove the record from the cache and insert the new one.
      if (previousEdge && existing) {
        uniqueExisting = {
          ...existing,
          edges: existing.edges.filter(
            (edge) =>
              isEdgeWithNodeAndRef(edge) && !incomingRefs.includes(edge.node.__ref),
          ),
        };
      }

      result = {
        edges: [...(uniqueExisting.edges ?? []), ...incoming.edges],
        pageInfo: existing?.pageInfo || result.pageInfo,
        totalCount: existing?.totalCount || result.totalCount,
      };
    } else {
      // If the query do not return the record, that means that it does not belong to the list anymore,
      // so we remove it
      return {
        ...result,
        ...existing,
        edges:
          existing?.edges.filter((edge) =>
            isEdgeWithNodeAndRef(edge)
              ? filterExistingNodePredicate(args, edge.node.__ref)
              : true,
          ) ?? [],
      };
    }

    return result;
  } else if (multipleRecordPredicate(args)) {
    let result = makeEmptyData();
    const hasEdge = incoming?.edges?.length;

    // If we get the record, we need to check if it is an existing one or fresh new, using the cache ref
    if (hasEdge) {
      const existingEdges = existing?.edges || [];
      const incomingRefs = mapEdgesToRefs(incoming.edges);
      const hasPreviousEdge = existingEdges.some(
        (edge) => isEdgeWithNodeAndRef(edge) && incomingRefs.includes(edge.node.__ref),
      );

      let uniqueExisting = { ...existing };

      // If the record was found in the cache, we remove the record from the cache and insert the new one.
      if (hasPreviousEdge && existing) {
        uniqueExisting = {
          ...existing,
          edges: existing.edges.filter(
            (edge) =>
              isEdgeWithNodeAndRef(edge) && !incomingRefs.includes(edge.node.__ref),
          ),
        };
      }

      result = {
        edges: [...(uniqueExisting.edges ?? []), ...incoming.edges],
        pageInfo: existing?.pageInfo || result.pageInfo,
        totalCount: existing?.totalCount || result.totalCount,
      };
    } else {
      // If the query do not return the record, that means that it does not belong to the list anymore,
      // so we remove it
      const refIds = mapArgsIdsToCacheKeys?.(args) ?? [];

      return {
        ...result,
        ...existing,
        edges:
          existing?.edges.filter((edge) =>
            isEdgeWithNodeAndRef(edge) ? !refIds.includes(edge.node.__ref) : true,
          ) ?? [],
      };
    }

    return result;
  } else {
    // This happens when don't retrieve the edge property from the query,
    // For example when we only need the totalCount
    if (incoming?.edges === undefined) {
      return { ...existing, ...incoming };
    }

    // Fetching multiple records scenario:
    //
    // We get the refs from the incoming records
    const incomingRefs = mapEdgesToRefs(incoming?.edges);
    let uniqueExisting;

    // If we have incoming records and existing records, we remove the ones from the cache that are present
    // in the incoming ones
    if (existing && existing.edges && existing.edges.length && incomingRefs.length) {
      uniqueExisting = {
        ...existing,
        edges: existing.edges.filter(
          (edge) => isEdgeWithNodeAndRef(edge) && !incomingRefs.includes(edge.node.__ref),
        ),
      };
    } else {
      uniqueExisting = { ...(existing ?? makeEmptyData()) };
    }

    // Get the page info based on the args, so we do not override the properties when we fetch on both
    // directions for the same list
    const computePageInfo = () => {
      if ('before' in args && args.before) {
        return {
          hasNextPage: existing?.pageInfo?.hasNextPage ?? false,
          nextCursor: existing?.pageInfo?.nextCursor ?? false,
          hasPreviousPage: incoming?.pageInfo.hasPreviousPage ?? false,
          previousCursor: incoming?.pageInfo.previousCursor ?? false,
        };
      } else if ('after' in args && args.after) {
        return {
          hasNextPage: incoming?.pageInfo?.hasNextPage ?? false,
          nextCursor: incoming?.pageInfo?.nextCursor ?? false,
          hasPreviousPage: existing?.pageInfo.hasPreviousPage ?? false,
          previousCursor: existing?.pageInfo.previousCursor ?? false,
        };
      } else {
        return {
          ...existing?.pageInfo,
          ...incoming?.pageInfo,
        };
      }
    };

    const computeEdges = () => {
      if ('before' in args && args.before) {
        return [...(incoming?.edges ?? []), ...uniqueExisting.edges];
      } else if ('after' in args && args.after) {
        return [...uniqueExisting.edges, ...(incoming?.edges ?? [])];
      } else {
        return incoming.edges;
      }
    };

    return {
      edges: computeEdges(),
      pageInfo: computePageInfo(),
      totalCount: incoming?.totalCount ?? existing?.totalCount,
    };
  }
};

const pageBasedPaginationMerge = (options: { existing; incoming; options }) => {
  const { incoming, existing } = options;

  if (!incoming) {
    return existing;
  }

  return incoming;
};

const isomorphicPagination = (options: {
  keyArgs?: KeyArgs;
  singleRecordPredicate: (args: Record<string, any> | null) => boolean;
  filterExistingNodePredicate: (args: Record<string, any> | null, ref: string) => boolean;
  multipleRecordPredicate?: (args: Record<string, any> | null) => boolean;
  mapArgsIdsToCacheKeys?: (args: Record<string, any> | null) => string[];
}) => {
  const {
    keyArgs,
    singleRecordPredicate,
    filterExistingNodePredicate,
    multipleRecordPredicate = () => false,
    mapArgsIdsToCacheKeys,
  } = options;

  return {
    keyArgs,
    read: (existing, options) => {
      const paginationType = getPaginationTypeFromOptions(options);

      if (paginationType === 'infinite-scroll') {
        const read = relayStylePagination().read;
        return read ? read(existing, options) : read;
      }

      return existing;
    },
    merge: (existing = [], incoming = [], options) => {
      const paginationType = getPaginationTypeFromOptions(options);

      if (paginationType === 'infinite-scroll') {
        return infiniteScrollPaginationMerge({
          existing,
          incoming,
          options,
          singleRecordPredicate,
          filterExistingNodePredicate,
          multipleRecordPredicate,
          mapArgsIdsToCacheKeys,
        });
      }

      return pageBasedPaginationMerge({ incoming, existing, options });
    },
  };
};

export const possibleTypes = {
  ContactTimeline: [
    'ContactTimelineContactCreated',
    'ContactTimelineContactStatusUpdated',
    'ContactTimelineContactUpdated',
    'ContactTimelineTaskCreated',
    'ContactTimelineTaskStatusUpdated',
    'ContactTimelineTaskDeleted',
    'ContactTimelineScheduleCreated',
    'ContactTimelineScheduleStatusUpdated',
    'ContactTimelineScheduleDeleted',
    'ContactTimelineNoteCreated',
    'ContactTimelineNoteDeleted',
    'ContactTimelineContactInteraction',
  ],
  ContactTimelineWithTask: [
    'ContactTimelineTaskCreated',
    'ContactTimelineTaskStatusUpdated',
  ],
  ContactTimelineWithSchedule: [
    'ContactTimelineScheduleCreated',
    'ContactTimelineScheduleStatusUpdated',
  ],
  ContactTimelineWithOriginator: [
    'ContactTimelineContactStatusUpdated',
    'ContactTimelineContactUpdated',
    'ContactTimelineTaskCreated',
    'ContactTimelineTaskStatusUpdated',
    'ContactTimelineTaskDeleted',
    'ContactTimelineScheduleCreated',
    'ContactTimelineScheduleStatusUpdated',
    'ContactTimelineScheduleDeleted',
    'ContactTimelineNoteCreated',
    'ContactTimelineNoteDeleted',
    'ContactTimelineContactInteraction',
  ],
  ContactInteraction: [
    'ContactInteractionEmail',
    'ContactInteractionFacebookAd',
    'ContactInteractionSms',
    'ContactInteractionPhoneCall',
    'ContactInteractionFacebookMessenger',
    'ContactInteractionInstagram',
    'ContactInteractionInPerson',
    'ContactInteractionWhatsapp',
    'ContactInteractionUnknown',
  ],
  ContactInteractionWithAttachments: [
    'ContactInteractionEmail',
    'ContactInteractionWhatsapp',
    'ContactInteractionSms',
    'ContactInteractionFacebookMessenger',
    'ContactInteractionInstagram',
  ],
  ContactInteractionChatMessage: [
    'ContactInteractionWhatsapp',
    'ContactInteractionSms',
    'ContactInteractionFacebookMessenger',
    'ContactInteractionInstagram',
  ],
  ContactInteractionWithDirection: [
    'ContactInteractionEmail',
    'ContactInteractionFacebookMessenger',
    'ContactInteractionInstagram',
    'ContactInteractionPhoneCall',
    'ContactInteractionSms',
    'ContactInteractionUnknown',
    'ContactInteractionWhatsapp',
  ],
  NotificationFeedItem: [
    'NotificationFeedItemContactAssigned',
    'NotificationFeedItemContactUnassigned',
    'NotificationFeedItemContactStatusChanged',
    'NotificationFeedItemContactUpdated',
    'NotificationFeedItemTaskAssigned',
    'NotificationFeedItemTaskUpdated',
    'NotificationFeedItemTaskCompleted',
    'NotificationFeedItemTaskOverdue',
    'NotificationFeedItemTaskReminder',
    'NotificationFeedItemScheduleAssigned',
    'NotificationFeedItemScheduleUpdated',
    'NotificationFeedItemScheduleCanceled',
    'NotificationFeedItemScheduleCreated',
    'NotificationFeedItemCallMissed',
    'NotificationFeedItemContactInteractionNew',
    'NotificationFeedItemConversationNoteNew',
    'NotificationFeedItemConversationNoteMention',
    'NotificationFeedItemReportReady',
    'NotificationFeedItemFacebookPageAccessTokenExpired',
    'NotificationFeedItemFacebookPageAccessTokenMissingPermissions',
    'NotificationFeedItemMessageDeliveryFailurePayment',
  ],
  ScheduleAttendee: [
    'ScheduleUserAttendee',
    'ScheduleContactAttendee',
    'ScheduleEmailAttendee',
  ],
  ContactInteractionChannelResource: [
    'ContactInteractionChannelPhoneResource',
    'ContactInteractionChannelSmsResource',
    'ContactInteractionChannelGenericResource',
  ],
  ContactInteractionAttachment: [
    'ContactInteractionAttachmentFile',
    'ContactInteractionAttachmentLocation',
    'ContactInteractionAttachmentContacts',
  ],
  ContactInteractionBaseReferral: [
    'ContactInteractionReferralImage',
    'ContactInteractionReferralLink',
    'ContactInteractionReferralVideo',
  ],
  ContactInteractionReferral: ['ContactInteractionChatMessageField'],
  ContactInteractionComponent: [
    'ContactInteractionChatMessageField',
    'ContactInteractionAttachmentFile',
    'ContactInteractionAttachmentLocation',
    'ContactInteractionAttachmentContacts',
    'ContactInteractionWhatsappTemplate',
  ],
  ContactRelatedNoteUnion: ['ConversationNote', 'ContactNote'],
  ContactInteractionWhatsappTemplateComponent: [
    'ContactInteractionWhatsappTemplateComponentHeader',
    'ContactInteractionWhatsappTemplateComponentBody',
    'ContactInteractionWhatsappTemplateComponentFooter',
  ],
};

const infiniteScrollPagination = (options: {
  keyArgs?: KeyArgs;
  singleRecordPredicate: (args: Record<string, any> | null) => boolean;
  filterExistingNodePredicate: (args: Record<string, any> | null, ref: string) => boolean;
  multipleRecordPredicate?: (args: Record<string, any> | null) => boolean;
  mapArgsIdsToCacheKeys?: (args: Record<string, any> | null) => string[];
}) => {
  const {
    keyArgs,
    singleRecordPredicate,
    filterExistingNodePredicate,
    multipleRecordPredicate = () => false,
    mapArgsIdsToCacheKeys,
  } = options;

  return {
    keyArgs,
    read: relayStylePagination().read,
    merge: (existing, incoming, options) =>
      infiniteScrollPaginationMerge({
        existing,
        incoming,
        options,
        singleRecordPredicate,
        multipleRecordPredicate,
        mapArgsIdsToCacheKeys,
        filterExistingNodePredicate,
      }),
  };
};

export const createApolloClient = (params: { currentBuild: BuildInfo }) => {
  // https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
  const errorLink = onError(({ operation }) => {
    const context = operation.getContext();
    // eslint-disable-next-line no-console
    console.log('request id:', context?.headers['lgg-request-id']);
    // if (graphQLErrors)
    //   graphQLErrors.forEach(({ message, locations, path }) =>
    //     console.log(
    //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    //     ),
    //   );
    // if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  // https://www.apollographql.com/docs/react/networking/advanced-http-networking/#customizing-request-logic
  const requestIdentifyingHeadersMiddleware = new ApolloLink((operation, forward) => {
    const token = getLocalStorageItem(LOCAL_STORAGE_KEY_API_TOKEN);
    const { resolvedLanguage } = getI18n();
    const authorizationHeader: Record<string, string> = token
      ? { Authorization: `Bearer ${token}` }
      : {};
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...authorizationHeader,
        ...requestIdentifyingHeaders({ build: params.currentBuild }),
        'accept-language': resolvedLanguage,
      },
    }));
    return forward(operation);
  });

  const httpLink = new HttpLink({
    uri: (operation) => {
      let uriPath = `${apiUrl()}/graphql`;

      if (operation.operationName) {
        uriPath += `/${encodeURIComponent(operation.operationName)}`;
      }

      if (Object.keys(operation.variables || {}).length > 0) {
        uriPath += `?${qs.stringify(operation.variables)}`;
      }

      return uriPath;
    },

    credentials: 'include',
  });

  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeQuery: true,
      includeError: true,
      includeFetchResult: true,
    },
  });

  // const wsLink = new WebSocketLink({
  //   uri: `ws${import.meta.env.MODE === 'production' ? 's' : ''}://${host()}/graphql`,
  //   options: {
  //     reconnect: true,
  //   },
  // });
  //
  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return (
  //       definition.kind === 'OperationDefinition' &&
  //       definition.operation === 'subscription'
  //     );
  //   },
  //   wsLink,
  //   httpLink,
  // );

  return new ApolloClient({
    link: from([errorLink, sentryLink, requestIdentifyingHeadersMiddleware, httpLink]),
    credentials: 'include',
    connectToDevTools: import.meta.env.DEV,
    defaultOptions: {
      watchQuery: {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#defining-a-merge-function-at-the-type-level
        ContactInteractionPhoneCallDetail: { merge: true },
        PhoneNumber: { merge: true },
        PageInfo: { merge: true },
        ContactInteractionChannel: { keyFields: ['slug'] },
        Conversation: {
          fields: {
            items: infiniteScrollPagination({
              singleRecordPredicate: (args) =>
                args?.where?.conversationNoteId || args?.where?.contactInteractionId,
              filterExistingNodePredicate: (args, ref) => {
                if (args?.where?.conversationNoteId) {
                  return ref !== 'ConversationNote:' + args?.where?.conversationNoteId;
                } else if (args?.where?.contactNoteId) {
                  return ref !== 'ContactNote:' + args?.where?.contactNoteId;
                } else {
                  return (
                    ref.startsWith('ContactInteraction') &&
                    ref.endsWith(':' + args?.where?.contactInteractionId)
                  );
                }
              },
            }),
          },
        },
        User: {
          merge: true,
          fields: {
            avatar: {
              merge: (existing, incoming = {}) => ({ ...existing, ...incoming }),
            },
          },
        },
        Query: {
          fields: {
            contacts: {
              keyArgs: ['institutionId'],
              merge: (existing = [], incoming = []) => {
                if (!incoming) {
                  return existing;
                }

                return incoming;
              },
            },
            contactInteractions: {
              keyArgs: ['institutionId'],
              merge: (existing = [], incoming = []) => {
                if (!incoming) {
                  return existing;
                }

                return incoming;
              },
            },
            schedules: isomorphicPagination({
              keyArgs: (args) =>
                JSON.stringify({
                  institutionId: args?.institutionId,
                  where: args?.where ?? 'CUSTOM',
                }),
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) =>
                ref !== 'Schedule:' + args?.where?.id._eq,
            }),
            tasks: isomorphicPagination({
              keyArgs: (args) =>
                JSON.stringify({
                  institutionId: args?.institutionId,
                  where: args?.where ?? 'CUSTOM',
                }),
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) =>
                ref !== 'Task:' + args?.where?.id._eq,
            }),
            contactNotes: isomorphicPagination({
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) =>
                ref !== 'ContactNote:' + args?.where?.id._eq,
            }),
            contactRelatedNotes: infiniteScrollPagination({
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) => {
                return (
                  (ref.startsWith('ConversationNote') || ref.startsWith('ContactNote')) &&
                  ref.endsWith(':' + args?.where?.id?._eq)
                );
              },
            }),
            contactTags: infiniteScrollPagination({
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) =>
                ref !== 'ContactTag:' + args?.where?.id._eq,
            }),
            users: infiniteScrollPagination({
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) =>
                ref !== 'User:' + args?.where?.id._eq,
            }),
            contactTimeline: infiniteScrollPagination({
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) => {
                return (
                  ref.startsWith('ContactTimeline') &&
                  ref.endsWith(':' + args?.where?.id?._eq)
                );
              },
            }),
            conversations: infiniteScrollPagination({
              keyArgs: ['institutionId'],
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) =>
                ref !== 'Conversation:' + args?.where?.id._eq,
              multipleRecordPredicate: (args) => Boolean(args?.where?.id?._in),
              mapArgsIdsToCacheKeys: (args) =>
                args?.where?.id._in.map((id) => 'Conversation:' + id) ?? [],
            }),
            notificationFeedItems: infiniteScrollPagination({
              keyArgs: ['where', ['status']],
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) =>
                !ref.endsWith(':' + args?.where?.id?._eq),
            }),
            whatsappTemplates: infiniteScrollPagination({
              singleRecordPredicate: (args) => Boolean(args?.where?.id?._eq),
              filterExistingNodePredicate: (args, ref) =>
                !ref.endsWith(':' + args?.where?.id?._eq),
              keyArgs: ['institutionId', 'resourceId'],
            }),
          },
        },
      },
    }),
  });
};

export const ApolloProviderProvider = memo(({ children }) => {
  const { currentBuild } = useService(AppStatusService, () => []);
  const apolloClient = useMemo(
    () => createApolloClient({ currentBuild }),
    [currentBuild],
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
});

// Be aware when using fragments that we do not over fetch. An example could be the contact object,
// For many queries we only ask for specific properties, like `id` and `label`, we could
// have a fragment for contact, but if we include all the nested properties, we will end up
// making extra SQL queries in the API, for example (campaign, source, etc), that we will not be used
// in the front end, so, when creating a fragment, make sure that the properties picked are always required
// when using such fragment in a query, so we do not waste extra resources for unneeded data
export const CORE_REACTION_FIELDS = gql`
  fragment ContactInteractionComponentReactionsFragment on ContactInteractionComponent {
    reactions {
      createdAt
      emoji
      contact {
        id
        label
        status {
          id
          name
        }
        stage {
          id
          slug
        }
      }
      user {
        id
        fullName
      }
    }
  }
`;

export const CORE_REFERRAL_FIELDS = gql`
  fragment ContactInteractionReferralFragment on ContactInteractionReferral {
    referral {
      ... on ContactInteractionBaseReferral {
        type
        url
        source
        headline
        body
      }
      ... on ContactInteractionReferralImage {
        imageUrl
      }
      ... on ContactInteractionReferralVideo {
        thumbnailUrl
      }
    }
  }
`;

export const CORE_ATTACHMENTS_FIELDS = gql`
  fragment ContactInteractionAttachmentsFragment on ContactInteractionWithAttachments {
    attachments {
      ... on ContactInteractionAttachment {
        id
        status
      }
      ... on ContactInteractionAttachmentFile {
        type
        filename
        originalFilename
        url
        mime
        length
        caption
      }
      ... on ContactInteractionAttachmentLocation {
        title
        lat
        long
        status
        address
      }
      ... on ContactInteractionAttachmentContacts {
        contacts {
          addresses {
            city
            country
            countryCode
            state
            street
            type
            zip
          }
          birthday
          emails {
            email
            type
          }
          name {
            firstName
            formattedName
            lastName
          }
          org {
            company
            department
          }
          phones {
            phone
            type
          }
          urls {
            url
            type
          }
        }
      }
    }
  }
`;

export const CONTACT_INTERACTION_COMPONENT_STATUS = gql`
  fragment ContactInteractionComponentStatus on ContactInteractionComponent {
    status
    statusDetails {
      id
      message
    }
  }
`;

export const CONTACT_INTERACTION_PARTICIPATING_ENTITY_CORE_FIELDS = gql`
  fragment ContactInteractionParticipatingEntityFragment on ContactInteractionParticipatingEntityEdge {
    id
    type
    label
  }
`;

export const CORE_RELATED_FIELDS = gql`
  ${CORE_ATTACHMENTS_FIELDS}
  ${CONTACT_INTERACTION_PARTICIPATING_ENTITY_CORE_FIELDS}
  fragment ContactInteractionComponentRelatedFragment on ContactInteractionComponent {
    relatedContactInteraction {
      ... on ContactInteraction {
        id
        isMasked
        participatingEntity {
          ...ContactInteractionParticipatingEntityFragment
        }
        contact {
          id
          label
        }
      }
      ... on ContactInteractionWithDirection {
        direction
      }
      ...ContactInteractionAttachmentsFragment
      ... on ContactInteractionChatMessage {
        message {
          id
          content
          status
        }
      }
      ... on ContactInteractionWhatsapp {
        template {
          id
          components {
            ... on ContactInteractionWhatsappTemplateComponent {
              text
              format
            }
            ... on ContactInteractionWhatsappTemplateComponentBody {
              variables {
                text
              }
            }
            ... on ContactInteractionWhatsappTemplateComponentHeader {
              variables {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export const CORE_ATTACHMENTS_FIELDS_WITH_REACTIONS_AND_RELATED = gql`
  ${CONTACT_INTERACTION_COMPONENT_STATUS}
  ${CORE_REACTION_FIELDS}
  fragment ContactInteractionAttachmentsFragmentWithReactionsAndRelated on ContactInteractionWithAttachments {
    attachments {
      ... on ContactInteractionAttachment {
        id
        ...ContactInteractionComponentStatus
      }
      ...ContactInteractionComponentReactionsFragment
      ...ContactInteractionComponentRelatedFragment
      ... on ContactInteractionAttachmentFile {
        type
        filename
        originalFilename
        url
        mime
        length
        caption
      }
      ... on ContactInteractionAttachmentLocation {
        title
        lat
        long
        status
        address
      }
      ... on ContactInteractionAttachmentContacts {
        contacts {
          addresses {
            city
            country
            countryCode
            state
            street
            type
            zip
          }
          birthday
          emails {
            email
            type
          }
          name {
            firstName
            formattedName
            lastName
          }
          org {
            company
            department
          }
          phones {
            phone
            type
          }
          urls {
            url
            type
          }
        }
      }
    }
  }
`;

export const CORE_CHAT_MESSAGES_FIELDS = gql`
  ${CONTACT_INTERACTION_COMPONENT_STATUS}
  fragment ContactInteractionChatMessagesFragment on ContactInteractionChatMessage {
    message {
      id
      content
      ...ContactInteractionComponentStatus
    }
  }
`;

export const CORE_CHAT_MESSAGES_FIELDS_WITH_REACTIONS_AND_RELATED = gql`
  ${CORE_REACTION_FIELDS}
  ${CORE_RELATED_FIELDS}
  ${CORE_REFERRAL_FIELDS}
  ${CONTACT_INTERACTION_COMPONENT_STATUS}
  fragment ContactInteractionChatMessagesFragmentWithReactionsAndRelated on ContactInteractionChatMessage {
    message {
      id
      content
      ...ContactInteractionComponentStatus
      ...ContactInteractionComponentReactionsFragment
      ...ContactInteractionComponentRelatedFragment
      ...ContactInteractionReferralFragment
    }
  }
`;

export const CORE_PAGE_INFO_FIELDS = gql`
  fragment PageInfoFragment on PageInfo {
    hasPreviousPage
    hasNextPage
    endCursor
    startCursor
  }
`;

export const CONTACT_INTERACTION_WHATSAPP_TEMPLATE_COMPONENT_BUTTONS_FRAGMENT = gql`
  fragment ContactInteractionWhatsappTemplateComponentButtonsFragment on ContactInteractionWhatsappTemplateComponentButtons {
    buttons {
      ... on WhatsappTemplateButtonComponent {
        type
      }
      ... on WhatsappTemplateButtonUrl {
        text
      }
      ... on WhatsappTemplateButtonQuickReply {
        text
      }
      ... on WhatsappTemplateButtonPhoneNumber {
        text
      }
    }
  }
`;

export const WHATSAPP_TEMPLATE_COMPONENT_BUTTONS_FRAGMENT = gql`
  fragment WhatsappTemplateComponentButtonsFragment on WhatsappTemplateComponentButtons {
    buttons {
      ... on WhatsappTemplateButtonComponent {
        type
      }
      ... on WhatsappTemplateButtonUrl {
        text
      }
      ... on WhatsappTemplateButtonQuickReply {
        text
      }
      ... on WhatsappTemplateButtonPhoneNumber {
        text
      }
    }
  }
`;

export const CORE_NOTIFICATION_CONTACT_FIELDS = gql`
  fragment NotificationContactFragment on NotificationContact {
    id
    label
    createdAt
  }
`;

export const CORE_NOTIFICATION_USER_FIELDS = gql`
  fragment NotificationUserFragment on NotificationFeedItemUser {
    id
    fullName
  }
`;

export const CONTACT_BLOCKING_FIELDS = gql`
  fragment ContactBlockingFieldsFragment on Contact {
    isBlocked
    blockedAt
    blockReason
    blockedBy {
      id
      fullName
    }
  }
`;
