import styled from 'styled-components';
import { Icon } from 'src/components/general/icon';

export const PluginTriggerIcon = styled(Icon)`
  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }

  &.active,
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }
`;
