import { useCallback } from 'react';
import { gql, useQuery, ApolloQueryResult } from '@apollo/client';
import { last } from 'lodash';
import {
  ChannelResourceSms,
  ChannelResourceSmsWhereInput,
  Query,
  QueryChannelResourcesSmsArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

export const GET_CHANNEL_RESOURCES_SMS = gql`
  query GetChannelResourcesSms(
    $institutionId: Int!
    $where: ChannelResourceSmsWhereInput
    $after: String
    $first: Int
  ) {
    channelResourcesSms(
      institutionId: $institutionId
      where: $where
      after: $after
      first: $first
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        nextCursor
        previousCursor
        startCursor
      }
      edges {
        node {
          id
          isActive
          displayName
          address
          phoneNumber {
            national
          }
        }
      }
    }
  }
`;

type CompanySmsChannelResourcesRefetchType = (
  variables?: Partial<Partial<QueryChannelResourcesSmsArgs>> | undefined,
) => Promise<ApolloQueryResult<Pick<Query, 'channelResourcesSms'>>>;

export type UseCompanySmsChannelResourcesReturn = {
  loading: boolean;
  resources: ChannelResourceSms[];
  refetch: CompanySmsChannelResourcesRefetchType;
  loadMore: (variables?: Partial<Partial<QueryChannelResourcesSmsArgs>>) => Promise<void>;
  hasNextPage?: boolean;
  called: boolean;
};

export const useCompanySmsChannelResources = (
  where?: ChannelResourceSmsWhereInput,
): UseCompanySmsChannelResourcesReturn => {
  const currentInstitution = useCurrentInstitution();
  const handleGraphQLError = useHandleGraphQLError();
  const { data, loading, refetch, fetchMore, called } = useQuery<
    Pick<Query, 'channelResourcesSms'>,
    Partial<QueryChannelResourcesSmsArgs>
  >(GET_CHANNEL_RESOURCES_SMS, {
    variables: {
      institutionId: currentInstitution.id,
      first: 100,
      where,
    },
    onError: handleGraphQLError,
  });

  const loadMore = useCallback(async () => {
    const lastCursor = last(data?.channelResourcesSms.edges)?.cursor;

    if (!lastCursor) {
      return;
    }

    await fetchMore({
      variables: {
        after: lastCursor,
        first: 100,
        where,
      },
    });
  }, [data?.channelResourcesSms.edges, fetchMore, where]);

  return {
    resources: getNodesFromConnection(data?.channelResourcesSms),
    loading,
    refetch,
    loadMore,
    called,
    hasNextPage: data?.channelResourcesSms?.pageInfo.hasNextPage,
  };
};
