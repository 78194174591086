import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { LexicalEditor as LexicalEditorType } from 'lexical';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  Mutation,
  MutationConversationSendSmsArgs,
  ContactInteractionChannelResourceUnion,
  ContactInteractionChannelAvailabilityForContactResourceToContactAddressAvailability,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { DrawerSelectableOption } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { FlexColumn } from 'src/components/layout/flex-column';
import {
  SmsLimitReachedBanner,
  SmsOptOutBanner,
  SmsResourceNotLinkedBanner,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/sms/sms';
import { SmsCounter } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/sms/sms-counter';
import { ContactInteractionInputManagerContext } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-manager';
import { CustomEditor } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/custom-editor';
import {
  CustomEditorProps,
  mapChannelResourcesToOptions,
  useHandleReplyError,
  useMapContactAddressesToOptions,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/editors/helpers';
import { CONVERSATION_SEND_SMS } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/graphql-operations';
import { ResourceSelectorBar } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/resource-selector-bar';
import { ContactPhoneOption } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';
import { useAuthorization } from 'src/hooks/use-authorization';

const SmsCounterContainer = styled.div`
  margin-right: 10px;

  ${up('md')} {
    margin-right: 15px;
  }
`;

export const SmsEditor = memo<CustomEditorProps>(
  ({ handleFetchNewMessageCompleted, channelAvailability }) => {
    const {
      messageValues,
      attachmentList,
      contactPhoneNumbers,
      conversationId,
      hasFocus,
    } = useContext(ContactInteractionInputManagerContext);

    const [selectedResource, setSelectedResource] =
      useState<ContactInteractionChannelResourceUnion | null>(null);
    const [selectedContactAddress, setSelectedContactAddress] =
      useState<ContactPhoneOption | null>(null);
    const [selectedResourceAvailability, setSelectedResourceAvailability] =
      useState<ContactInteractionChannelAvailabilityForContactResourceToContactAddressAvailability | null>(
        null,
      );

    const { mapContactAddressesToOptions } = useMapContactAddressesToOptions();
    const { t } = useTranslation(['errors', 'conversations', 'common', 'smsChannel']);
    const handleReplyError = useHandleReplyError();
    const { getFeatureFlag } = useAuthorization();
    const isAttachmentsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-265_como-usuario-quiero-poder-adjuntar-archivos-de-modo-que-pueda-compartir-con-mi-cliente-detalles-rele',
    );
    const isSmsAndFacebookMessengerAttachmentsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-1887_deshabilitar-attachments-para-sms-y-facebook-messenger',
    );
    const isAiToolsFeatureEnabled = getFeatureFlag('AI');

    const [sendSms, { loading: isSendingSms }] = useMutation<
      Pick<Mutation, 'conversationSendSms'>,
      MutationConversationSendSmsArgs
    >(CONVERSATION_SEND_SMS, {
      onError: handleReplyError,
    });

    useEffect(() => {
      if (!channelAvailability) {
        return;
      }
      const { resources, lastUsedResourceId, lastInteractionContactAddress } =
        channelAvailability;

      setSelectedResourceAvailability(
        channelAvailability.resourceToContactAddressAvailability?.find(
          (resource) =>
            resource.resourceId === selectedResource?.id &&
            resource.contactAddress === selectedContactAddress?.e164,
        ) ?? null,
      );

      if (!selectedResource) {
        const defaultSelectedResource =
          resources.find(({ id }) => id === lastUsedResourceId) ?? resources[0] ?? null;

        setSelectedResource(defaultSelectedResource);
      }

      if (!selectedContactAddress) {
        const defaultSelectedContactAddress: ContactPhoneOption | null =
          contactPhoneNumbers.find(
            (phone) => phone.e164 === lastInteractionContactAddress,
          ) ??
          contactPhoneNumbers[0] ??
          null;

        setSelectedContactAddress(defaultSelectedContactAddress);
      }
    }, [
      contactPhoneNumbers,
      channelAvailability,
      selectedResource,
      selectedContactAddress,
    ]);

    const handleSendMessage = useCallback(
      async (editor: LexicalEditorType) => {
        if (!conversationId || isSendingSms) {
          return;
        }

        let commonVariables: Pick<
          MutationConversationSendSmsArgs,
          'conversationId' | 'message' | 'attachments'
        > = {
          conversationId,
          message: messageValues.message ?? '',
          attachments: [],
        };

        const attachments = attachmentList.map(({ url }) => ({
          s3UploadedUrl: url ?? '',
        }));

        if (isAttachmentsFeatureEnabled && attachments.length > 0) {
          commonVariables = {
            ...commonVariables,
            attachments,
          };
        }

        await sendSms({
          variables: {
            ...commonVariables,
            contactPhoneType: selectedContactAddress?.type ?? null,
            fromResourceId: selectedResource?.id ? Number(selectedResource.id) : null,
          },
          onCompleted: async ({ conversationSendSms: data }) => {
            const interactionId = data.interaction?.id;

            if (!interactionId) {
              return;
            }

            await handleFetchNewMessageCompleted({
              editor,
              where: {
                contactInteractionId: {
                  eq: Number(interactionId),
                },
              },
            });
          },
        });
      },
      [
        isSendingSms,
        conversationId,
        messageValues.message,
        attachmentList,
        isAttachmentsFeatureEnabled,
        sendSms,
        selectedContactAddress?.type,
        selectedResource?.id,
        handleFetchNewMessageCompleted,
      ],
    );

    const isSmsResourceNotTcrLinked = useMemo(
      () =>
        selectedResource?.__typename === 'ContactInteractionChannelSmsResource' &&
        selectedResource.unavailableReasonCode === 'NO_CAMPAIGN',
      [selectedResource],
    );

    const isSmsResourceLimitReached = useMemo(
      () =>
        selectedResource?.__typename === 'ContactInteractionChannelSmsResource' &&
        selectedResource.unavailableReasonCode === 'SMS_LIMIT_REACHED',
      [selectedResource],
    );

    const isSmsResourceOptOut = useMemo(
      () =>
        selectedResource?.__typename === 'ContactInteractionChannelSmsResource' &&
        selectedResourceAvailability?.unavailableReasonCode === 'OPT_OUT',
      [selectedResource, selectedResourceAvailability],
    );

    const isInputEnabled = useMemo(
      () =>
        !isSmsResourceNotTcrLinked && !isSmsResourceLimitReached && !isSmsResourceOptOut,
      [isSmsResourceLimitReached, isSmsResourceNotTcrLinked, isSmsResourceOptOut],
    );

    const mapSmsResourcesToGroupedOptions = ({
      resources,
      testIdGenerator,
    }: {
      resources: ContactInteractionChannelResourceUnion[];
      testIdGenerator: (id: string | number) => string;
    }) => {
      return resources.reduce<{
        linked: DrawerSelectableOption[];
        notLinked: DrawerSelectableOption[];
      }>(
        (acc, resource) => {
          const { displayName, address, id: resourceId } = resource;

          if (resource.__typename === 'ContactInteractionChannelSmsResource') {
            const option = {
              label: resource.phoneNumber?.national ?? address,
              onClick: () => setSelectedResource(resource),
              subTitle: displayName ?? undefined,
              value: resourceId,
              'data-lgg-id': testIdGenerator(resourceId),
            };

            if (resource.unavailableReasonCode === 'NO_CAMPAIGN') {
              acc.notLinked.push(option);
            } else {
              acc.linked.push(option);
            }
          }

          return acc;
        },
        { linked: [], notLinked: [] },
      );
    };

    const getSmsResourceSelectorBar = useCallback(
      ({ testIdPrefix }: { testIdPrefix: string }) => {
        const resources = channelAvailability?.resources ?? [];

        return selectedResource && resources.length > 0 ? (
          <ResourceSelectorBar
            label={t('common:from')}
            selectedResource={selectedResource}
            options={(() => {
              const testIdGenerator = (id) => `${testIdPrefix}-option-${id}`;

              if (
                !getFeatureFlag(
                  'temp_lggdev-2163_sms-resources-grouped-by-linked-to-campaign',
                )
              ) {
                return mapChannelResourcesToOptions({
                  resources,
                  testIdGenerator,
                  onClick: setSelectedResource,
                });
              }

              const groupedOptions = mapSmsResourcesToGroupedOptions({
                resources,
                testIdGenerator,
              });

              return {
                [t('smsChannel:campaignRegistry.a2pVerified')]: groupedOptions.linked,
                [t('smsChannel:campaignRegistry.notVerified')]: groupedOptions.notLinked,
              };
            })()}
            data-lgg-id={`${testIdPrefix}-selector-dropdown-button`}
          />
        ) : null;
      },
      [getFeatureFlag, channelAvailability?.resources, selectedResource, t],
    );

    const getContactPhoneSelectorBar = useCallback(() => {
      return selectedContactAddress && contactPhoneNumbers.length > 0 ? (
        <ResourceSelectorBar
          label={t('common:to')}
          selectedResource={selectedContactAddress}
          options={mapContactAddressesToOptions({
            phoneOptions: contactPhoneNumbers,
            onClick: setSelectedContactAddress,
          })}
          data-lgg-id="contact-phone-selector-dropdown-button"
        />
      ) : null;
    }, [contactPhoneNumbers, mapContactAddressesToOptions, selectedContactAddress, t]);

    return (
      <CustomEditor
        handleSubmission={handleSendMessage}
        isSubmitting={isSendingSms}
        bottomContent={{
          visible: true,
        }}
        topContent={
          hasFocus ||
          isSmsResourceNotTcrLinked ||
          isSmsResourceLimitReached ||
          isSmsResourceOptOut ? (
            <FlexColumn>
              {getSmsResourceSelectorBar({
                testIdPrefix: 'sms-resource',
              })}
              {getContactPhoneSelectorBar()}
              {isSmsResourceNotTcrLinked ? <SmsResourceNotLinkedBanner /> : null}
              {isSmsResourceLimitReached ? <SmsLimitReachedBanner /> : null}
              {isSmsResourceOptOut ? <SmsOptOutBanner /> : null}
            </FlexColumn>
          ) : null
        }
        pluginConfig={{
          emojiPicker: true,
          messageAttachments:
            isAttachmentsFeatureEnabled &&
            isSmsAndFacebookMessengerAttachmentsFeatureEnabled,
          aiTools: isAiToolsFeatureEnabled,
        }}
        rightRailLeadingContent={
          <SmsCounterContainer>
            <SmsCounter
              message={messageValues.message}
              attachmentCount={attachmentList.length}
            />
          </SmsCounterContainer>
        }
        isInputEnabled={isInputEnabled}
      />
    );
  },
);
