import React, { memo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Landing } from 'src/components/routing/landing/landing';
import { AuthProvider } from '../providers/auth-provider';

export const MainRouter = memo(() => {
  return (
    <AuthProvider>
      <Router>
        <Landing />
      </Router>
    </AuthProvider>
  );
});
