import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Progress } from 'antd';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import * as zod from 'zod';
import { LggButton } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { GeneralAndAudienceStep } from 'src/components/pages/broadcast/components/broadcast-wizard/steps/general-and-audience-step';
import { MessageComposeStep } from 'src/components/pages/broadcast/components/broadcast-wizard/steps/message-compose-step';

export enum BroadcastWizardStep {
  AUDIENCE = 'AUDIENCE',
  MESSAGE_COMPOSE = 'MESSAGE_COMPOSE',
  SCHEDULE = 'SCHEDULE',
  OVERVIEW = 'OVERVIEW',
}

const BroadcastWizardStepOrder: BroadcastWizardStep[] = [
  BroadcastWizardStep.AUDIENCE,
  BroadcastWizardStep.MESSAGE_COMPOSE,
  BroadcastWizardStep.OVERVIEW,
  BroadcastWizardStep.SCHEDULE,
];

const StyledProgress = styled(Progress)`
  line-height: 0;

  .ant-progress-bg {
    background: ${({ theme }) => theme.colors.monk};
  }

  .ant-progress-inner {
    background: ${({ theme }) => theme.colors.koala};
  }
`;

const StepHeaderTopContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
`;

const StepTitleIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.cosmo};
    }
  }
`;

const CenteredRow = styled(FlexRow)`
  align-items: center;
`;

const StepProgressIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.colors.cosmo};
    }
  }
`;

const StepProgressText = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 18px;
  text-align: right;
`;

const StepTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  margin: 0;
  text-align: left;
`;

const HeaderMainContainer = styled(FlexColumn)`
  margin-bottom: 24px;
  padding: 20px 20px 0;
`;

type BroadcastWizardHeaderConfiguration = {
  icon: string;
  title: string;
  progress: number;
};

type BroadcastWizardHeaderProps = {
  step: BroadcastWizardStep;
};

const BroadcastWizardHeader = ({ step }: BroadcastWizardHeaderProps) => {
  const { t } = useTranslation(['broadcast']);

  const configuration: BroadcastWizardHeaderConfiguration = useMemo(() => {
    return match(step)
      .with(BroadcastWizardStep.AUDIENCE, () => {
        return {
          icon: 'contactSetting',
          title: t('broadcast:pages.broadcastWizard.steps.generalAndAudience.title'),
          progress: 0,
        };
      })
      .with(BroadcastWizardStep.MESSAGE_COMPOSE, () => {
        return {
          icon: 'sms',
          title: t('broadcast:pages.broadcastWizard.steps.smsCompose.title'),
          progress: 33,
        };
      })
      .with(BroadcastWizardStep.SCHEDULE, () => {
        return {
          icon: '',
          title: '',
          progress: 0,
        };
      })
      .with(BroadcastWizardStep.OVERVIEW, () => {
        return {
          icon: '',
          title: '',
          progress: 0,
        };
      })
      .exhaustive();
  }, [step, t]);

  const { icon, title, progress } = configuration;

  return (
    <HeaderMainContainer data-lgg-id="broadcast-wizard-header">
      <StepHeaderTopContainer>
        <CenteredRow>
          <StepTitleIcon type={icon} />
          <StepTitle>{title}</StepTitle>
        </CenteredRow>
        <CenteredRow>
          <StepProgressIcon type="circularCheck" />
          <StepProgressText data-lgg-id="broadcast-wizard-progress">
            {t('broadcast:pages.broadcastWizard.progress', { progress })}
          </StepProgressText>
        </CenteredRow>
      </StepHeaderTopContainer>
      <StyledProgress percent={progress} showInfo={false} strokeWidth={4} />
    </HeaderMainContainer>
  );
};

const BaseContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
`;

const WizardForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-right: 30px;
  width: 100%;
`;

const StepContent = styled(FlexColumn)`
  height: 100%;
  padding: 0 20px;
`;

const StepFooter = styled(FlexRow)`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  height: 78px;
  justify-content: space-between;
  padding: 20px 30px;
`;

const SaveAsDraftIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 18px;
    width: 18px;
  }
`;

const SaveAsDraftButton = styled(LggButton)`
  width: 145px;
`;

const BackButton = styled(LggButton)`
  margin-right: 15px;
`;

const wizardFormSchema = zod
  .object({
    name: zod.string().nullish(),
    sender: zod.string().nullish(),
    message: zod.string().nullish(),
    optOutMessage: zod.string().nullish(),
    optOutMessageLanguage: zod.union([zod.literal('EN'), zod.literal('ES')]).nullish(),
  })
  .required();

export type BroadcastWizardFormValues = zod.infer<typeof wizardFormSchema>;

export type BroadcastWizardStepProps = {
  form: UseFormReturn<BroadcastWizardFormValues>;
};

export type BroadcastWizardProps = {
  step: BroadcastWizardStep;
  setWizardStep: ValueChanged<BroadcastWizardStep>;
};

export const BroadcastWizard = ({ step, setWizardStep }: BroadcastWizardProps) => {
  const { t } = useTranslation(['common']);

  const formInitialValues = useMemo(() => {
    return {
      name: '',
      sender: '',
      message: '',
      optOutMessage: '',
      optOutMessageLanguage: 'EN' as const,
    };
  }, []);

  const wizardForm = useForm<BroadcastWizardFormValues>({
    defaultValues: {
      name: '',
      sender: '',
    },
    values: formInitialValues,
    shouldFocusError: true,
  });

  const wizardStepContent = useMemo(() => {
    return match(step)
      .with(BroadcastWizardStep.AUDIENCE, () => {
        return <GeneralAndAudienceStep form={wizardForm} />;
      })
      .with(BroadcastWizardStep.MESSAGE_COMPOSE, () => {
        return <MessageComposeStep form={wizardForm} />;
      })
      .with(BroadcastWizardStep.SCHEDULE, () => {
        return <></>;
      })
      .with(BroadcastWizardStep.OVERVIEW, () => {
        return <></>;
      })
      .exhaustive();
  }, [step, wizardForm]);

  const showBackButton = useMemo(() => step !== BroadcastWizardStep.AUDIENCE, [step]);

  const handleBack = useCallback(() => {
    const currentStepIndex = BroadcastWizardStepOrder.indexOf(step);

    if (currentStepIndex > 0) {
      setWizardStep(BroadcastWizardStepOrder[currentStepIndex - 1]);
    }
  }, [setWizardStep, step]);

  const handleNext = useCallback(() => {
    const currentStepIndex = BroadcastWizardStepOrder.indexOf(step);

    if (currentStepIndex < BroadcastWizardStepOrder.length - 1) {
      setWizardStep(BroadcastWizardStepOrder[currentStepIndex + 1]);
    }
  }, [setWizardStep, step]);

  return (
    <MainContainer>
      <BroadcastWizardHeader step={step} />
      <FormProvider {...wizardForm}>
        <WizardForm
          onSubmit={wizardForm.handleSubmit(() => {
            handleNext();
            // TODO: Implement form submission logic
          })}
        >
          <StepContent>{wizardStepContent}</StepContent>
          <StepFooter>
            <SaveAsDraftButton
              variant="tertiary"
              data-lgg-id="broadcast-wizard-save-as-draft-button"
            >
              <SaveAsDraftIcon type="draft" />
              {t('common:saveAsDraft')}
            </SaveAsDraftButton>
            <FlexRow>
              {showBackButton && (
                <BackButton
                  variant="tertiary"
                  data-lgg-id="broadcast-wizard-back-button"
                  onClick={handleBack}
                >
                  {t('common:back')}
                </BackButton>
              )}
              <LggButton
                variant="primary"
                type="submit"
                data-lgg-id="broadcast-wizard-next-button"
              >
                {t('common:next')}
              </LggButton>
            </FlexRow>
          </StepFooter>
        </WizardForm>
      </FormProvider>
    </MainContainer>
  );
};
