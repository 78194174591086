import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import c from 'classnames';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { LggOptionsDropdownButtonWithCustomTriggerControlled } from 'src/components/general/button/dropdown-button';
import { Table, TableColumns, TableProps } from 'src/components/general/display/table';
import { Icon } from 'src/components/general/icon';
import { getDefaultSortOrder, TableSortData } from 'src/components/general/table-helpers';
import { FlexColumn } from 'src/components/layout/flex-column';
import { useFormatDate } from 'src/hooks/use-format-date';

type BroadcastType = {
  id: number;
  name: string;
  status: 'SCHEDULED' | 'INPROGRESS' | 'SENT' | 'DRAFT' | 'CANCELLED';
  scheduledStartAt: Date;
  audience: number;
  sent: number;
  replies: number;
  optOuts: number;
};

const BroadcastStatusTag = styled(({ tagTheme, ...props }) => <Tag {...props} />)`
  background: ${({ theme, tagTheme }) => theme.colors[tagTheme.background]};
  border-color: ${({ theme, tagTheme }) => theme.colors[tagTheme.border]};
  border-radius: 2px;
  color: ${({ theme, tagTheme }) => theme.colors[tagTheme.color]};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 8px;
  height: 12px;
  letter-spacing: -0.16px;
  line-height: 10px;
  margin-top: 3px;
  padding: 0 4px;
  text-transform: uppercase;
  width: max-content;
`;

const BroadcastDescriptionContainer = styled(FlexColumn)`
  width: max-content;
`;

const BroadcastTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  text-align: left;
`;

const TableBoldText = styled.span`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.raven};

  &.success {
    color: ${({ theme }) => theme.colors.secondaryMintDark};
  }

  &.good {
    color: ${({ theme }) => theme.colors.secondaryGoldDark};
  }

  &.bad {
    color: ${({ theme }) => theme.colors.secondaryCoralDark};
  }
`;

const TableLightText = styled.span`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.colors.flint};
`;

const RowInfoContainer = styled(FlexColumn)`
  ${TableBoldText} {
    margin-bottom: 4px;
  }
`;

const shouldShowBroadcastDetail = (status: BroadcastType['status'], value: number) =>
  value > 0 && (status === 'INPROGRESS' || status === 'SENT');

const emptyPlaceholder = <TableLightText>–</TableLightText>;

const getPercentageLabel = (
  total: number,
  value: number,
  addValueColor: boolean = false,
) => {
  const percentage = total > 0 ? (value / total) * 100 : 0;

  const className = addValueColor
    ? match(percentage)
        .when(
          (percentage) => percentage < 3,
          () => 'success',
        )
        .when(
          (percentage) => percentage < 5,
          () => 'good',
        )
        .when(
          (percentage) => percentage >= 5,
          () => 'bad',
        )
        .otherwise(() => undefined)
    : undefined;

  return (
    <TableBoldText
      className={className ? c({ [className]: true }) : ''}
    >{`${percentage.toFixed(2)}%`}</TableBoldText>
  );
};

type BroadcastTableProps = {
  data: BroadcastType[];
  sortData: TableSortData;
  onChange: TableProps<BroadcastType>['onChange'];
};

export const BroadcastTable = memo<BroadcastTableProps>(
  ({ data, sortData = { key: 'status', direction: 'DESC' }, onChange }) => {
    const { t } = useTranslation(['common', 'broadcast']);
    const { key: sortKey, direction: sortDirection } = sortData;
    const { formatSimpleRelativeDate, formatSimpleTime } = useFormatDate();

    const getTagConfig = useCallback(
      (value: 'SCHEDULED' | 'INPROGRESS' | 'SENT' | 'DRAFT' | 'CANCELLED') => {
        return match(value)
          .with('SCHEDULED', () => ({
            color: 'secondaryGoldDark',
            border: 'secondaryGold60',
            background: 'secondaryGold10',
            label: t(
              'broadcast:pages.broadcast.broadcastTable.broadcastStatus.scheduled',
            ),
          }))
          .with('SENT', () => ({
            color: 'secondaryMintDark',
            border: 'secondaryMint60',
            background: 'secondaryMint10',
            label: t('broadcast:pages.broadcast.broadcastTable.broadcastStatus.sent'),
          }))
          .with('CANCELLED', () => ({
            color: 'secondaryCoralDark',
            border: 'secondaryCoral60',
            background: 'secondaryCoral10',
            label: t(
              'broadcast:pages.broadcast.broadcastTable.broadcastStatus.cancelled',
            ),
          }))
          .with('INPROGRESS', () => ({
            color: 'secondaryTopazDark',
            border: 'secondaryTopaz60',
            background: 'secondaryTopaz10',
            label: t(
              'broadcast:pages.broadcast.broadcastTable.broadcastStatus.inProgress',
            ),
          }))
          .with('DRAFT', () => ({
            color: 'raven',
            border: 'casper',
            background: 'koala',
            label: t('broadcast:pages.broadcast.broadcastTable.broadcastStatus.draft'),
          }))
          .exhaustive();
      },
      [t],
    );

    const columns: TableColumns<BroadcastType>[] = [
      {
        title: (
          <span data-lgg-id="broadcast-table-column-header-broadcast">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.broadcast')}
          </span>
        ),
        key: 'title',
        wrapContent: true,
        showSorterTooltip: false,
        sorter: true,
        render: (_, { name, status }) => {
          const { label, ...tagTheme } = getTagConfig(status);

          return (
            <BroadcastDescriptionContainer>
              <BroadcastTitle>{name}</BroadcastTitle>
              <BroadcastStatusTag tagTheme={tagTheme}>{label}</BroadcastStatusTag>
            </BroadcastDescriptionContainer>
          );
        },
      },
      {
        title: (
          <span data-lgg-id="broadcast-table-column-header-start-date">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.startDate')}
          </span>
        ),
        key: 'scheduledStartAt',
        dataIndex: 'scheduledStartAt',
        width: '122px',
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'createdAt', sortDirection),
        render: (scheduledStartAt: Date) => (
          <RowInfoContainer>
            <TableBoldText>{formatSimpleRelativeDate(scheduledStartAt)}</TableBoldText>
            <TableLightText>{formatSimpleTime(scheduledStartAt)}</TableLightText>
          </RowInfoContainer>
        ),
      },
      {
        title: (
          <span data-lgg-id="broadcast-table-column-header-audience">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.audience')}
          </span>
        ),
        key: 'audience',
        dataIndex: 'audience',
        width: '121px',
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'audience', sortDirection),
        render: (audience: number) => (
          <TableLightText>{audience.toLocaleString()}</TableLightText>
        ),
      },
      {
        title: (
          <span data-lgg-id="broadcast-table-column-header-sent">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.sent')}
          </span>
        ),
        key: 'sent',
        dataIndex: 'sent',
        width: '81px',
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'sent', sortDirection),
        render: (value: number, { status }) => {
          return shouldShowBroadcastDetail(status, value) ? (
            <TableLightText>{value}</TableLightText>
          ) : (
            emptyPlaceholder
          );
        },
      },
      {
        title: (
          <span data-lgg-id="broadcast-table-column-header-replies">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.replies')}
          </span>
        ),
        key: 'replies',
        dataIndex: 'replies',
        width: '112px',
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'replies', sortDirection),
        render: (value: number, { sent, status }) => {
          return shouldShowBroadcastDetail(status, value) ? (
            <RowInfoContainer>
              {getPercentageLabel(sent, value)}
              <TableLightText>{value}</TableLightText>
            </RowInfoContainer>
          ) : (
            emptyPlaceholder
          );
        },
      },
      {
        title: (
          <span data-lgg-id="broadcast-table-column-header-opted-out">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.optedOut')}
          </span>
        ),
        key: 'optOuts',
        dataIndex: 'optOuts',
        width: '136px',
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'optOuts', sortDirection),
        render: (optOuts: number, { sent, status }) => {
          return shouldShowBroadcastDetail(status, optOuts) ? (
            <RowInfoContainer>
              {getPercentageLabel(sent, optOuts, true)}
              <TableLightText>{optOuts > 0 ? optOuts : '-'}</TableLightText>
            </RowInfoContainer>
          ) : (
            emptyPlaceholder
          );
        },
      },
      {
        key: 'more-options',
        showSorterTooltip: false,
        width: '62px',
        wrapContent: true,
        render: (_, { status }) => {
          return (
            <LggOptionsDropdownButtonWithCustomTriggerControlled
              customTrigger={
                <Icon type="moreOptions" lggTestId="broadcast-table-cell-more-options" />
              }
              options={[
                ...(status === 'INPROGRESS'
                  ? [
                      {
                        label: t('common:cancel'),
                        icon: 'close',
                        'data-lgg-id': 'broadcast-option-cancel',
                        // TODO: ADD PROPER ACTION
                        to: '/',
                      },
                    ]
                  : []),
                {
                  label: t('common:delete'),
                  icon: 'deleteBin',
                  'data-lgg-id': 'broadcast-option-delete',
                  // TODO: ADD PROPER ACTION
                  to: '/',
                },
              ]}
            />
          );
        },
      },
    ];

    return (
      <Table
        rowKey={({ id }) => id}
        data-lgg-id="broadcast-table"
        columns={columns}
        tableLayout="fixed"
        loading={false}
        dataSource={data}
        pagination={false}
        showHeader={true}
        scroll={{ x: 1069 }}
        onChange={onChange}
        rowClassName={(record, index) =>
          `broadcast-table-row broadcast-row-${record.id} broadcast-row-position-${
            index + 1
          }`
        }
      />
    );
  },
);
