import React, { useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useService } from 'react-service-locator';
import { debounce, isEmpty } from 'lodash';
import styled from 'styled-components';
import { ContactWhereInput } from '@lgg/isomorphic/types/__generated__/graphql';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { TextInput } from 'src/components/general/inputs/text-input';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastWizardFormValues } from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import {
  WizardStepMessage,
  WizardStepSectionDescription,
  WizardStepSectionDivider,
  WizardStepSectionTitle,
} from 'src/components/pages/broadcast/components/broadcast-wizard/shared';
import { useBroadcastAvailableContactsForChannelSms } from 'src/components/pages/broadcast/hooks/use-broadcast-available-contacts-for-channel-sms';
import { useCompanySmsChannelResources } from 'src/components/pages/broadcast/hooks/use-company-sms-channel-resources';
import { ContactFilters } from 'src/components/pages/contacts/components/contact-filters';
import { useConvertFilterParamsToWhereInput } from 'src/components/pages/contacts/contact-queries';
import { useVisible } from 'src/hooks/use-visible';
import { BreadcrumbsService } from 'src/services/breadcrumbs.service';

const GeneralInformationContainer = styled(FlexColumn)`
  margin-bottom: 24px;
  margin-top: 20px;
  max-width: 406px;
  width: 100%;

  & + & {
    margin-bottom: 17px;
  }
`;

const GeneralInformationInputsContainer = styled.span`
  & > * {
    :not(:last-child) {
      margin-bottom: 17px;
    }
  }
`;

const AudienceSegmentationContainer = styled(FlexColumn)`
  margin-top: 24px;
  width: 406px;

  ${WizardStepSectionTitle} {
    margin-bottom: 2px;
  }

  ${WizardStepSectionDescription} {
    margin-bottom: 15px;
  }
`;

const FiltersButtonLightText = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 18px;
  text-align: left;
`;

const FiltersButton = styled(ButtonV2)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.cosmo};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  height: 34px;
  line-height: 18px;
  margin-bottom: 20px;
  text-align: left;
  white-space: break-spaces;
  width: max-content;

  &,
  &:hover {
    color: ${({ theme }) => theme.colors.smalt};
  }

  .lgg-icon {
    svg {
      height: 18px;
      width: 18px;

      path {
        fill: ${({ theme }) => theme.colors.smalt};
      }
    }
  }
`;

const AudienceSummaryContainer = styled(FlexColumn)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  margin-top: 20px;
  padding: 11px 15px;
`;

const AudienceSummaryItem = styled(FlexRow)`
  font-family: ${({ theme }) => theme.font.regular};
  justify-content: space-between;

  .result-divider {
    border-bottom: 1px solid ${({ theme }) => theme.colors.casper};
    padding-left: 10px;
  }
`;

const AudienceSummaryText = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  line-height: 18px;
  text-align: right;
`;

const AudienceSummaryBoldText = styled(AudienceSummaryText)`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.bold};
`;

type GeneralAndAudienceStepProps = {
  form: UseFormReturn<BroadcastWizardFormValues>;
};

export const GeneralAndAudienceStep = ({ form }: GeneralAndAudienceStepProps) => {
  const { t } = useTranslation(['broadcast']);
  const filtersVisibilityHandler = useVisible();
  const convertFormValuesToWhereInput = useConvertFilterParamsToWhereInput();
  const [filterWhere, setFilterWhere] = useState<ContactWhereInput | undefined>(
    undefined,
  );
  const [filterCount, setFilterCount] = useState<number>(0);
  const [selectedResourceId, setSelectedResourceId] = useState<number | undefined>(
    form.getValues().sender ? Number(form.getValues().sender) : undefined,
  );
  const { updateLastBreadcrumb } = useService(BreadcrumbsService, () => []);

  const debouncedBroadcastNameChangedHandler = useMemo(
    () =>
      debounce((name: string | undefined | null) => {
        updateLastBreadcrumb(name ?? 'Leadgogo');
      }, 300),
    [updateLastBreadcrumb],
  );

  useEffect(() => {
    const innerFormSubscription = form.watch(({ name, sender }) => {
      debouncedBroadcastNameChangedHandler(name);

      if (sender) {
        setSelectedResourceId(Number(sender));
      }
    });

    return () => {
      innerFormSubscription.unsubscribe();
      debouncedBroadcastNameChangedHandler.cancel();
    };
  }, [debouncedBroadcastNameChangedHandler, form]);

  const {
    resources: smsChannelResources,
    loadMore,
    loading: loadingSmsChannelResources,
    hasNextPage,
    called,
  } = useCompanySmsChannelResources({
    isActive: { _eq: true },
    tcrCampaign: { _eq: 'ACTIVE' },
  });

  const { loading: loadingAudienceSummary, summary: audienceSummary } =
    useBroadcastAvailableContactsForChannelSms(selectedResourceId, filterWhere);

  const noSenderOptionsAvailable = useMemo(
    () => !loadingSmsChannelResources && called && smsChannelResources.length === 0,
    [called, loadingSmsChannelResources, smsChannelResources.length],
  );

  const senderOptions: SelectOption<string>[] = useMemo(() => {
    return smsChannelResources.map((resource) => ({
      value: resource.id,
      label: resource.displayName,
    }));
  }, [smsChannelResources]);

  return (
    <FlexColumn data-lgg-id="broadcast-wizard-step-general-and-audience">
      <WizardStepSectionTitle>
        {t('broadcast:pages.broadcastWizard.steps.generalAndAudience.generalInformation')}
      </WizardStepSectionTitle>
      <GeneralInformationContainer>
        <GeneralInformationInputsContainer>
          <Controller
            control={form.control}
            name="name"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => {
              return (
                <TextInput
                  {...field}
                  data-lgg-id="broadcast-wizard-name-input"
                  error={Boolean(fieldState.error)}
                  label={t(
                    'broadcast:pages.broadcastWizard.steps.generalAndAudience.broadcastName',
                  )}
                  autoComplete="off"
                  reserveErrorArea={false}
                  showErrorMessage={false}
                  value={field.value ?? ''}
                />
              );
            }}
          />
          <Controller
            control={form.control}
            name="sender"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => {
              const selectedOption =
                senderOptions.find(
                  ({ value: optionValue }) => optionValue === field.value,
                ) ?? null;

              return (
                <Select
                  options={senderOptions}
                  isSearchable={false}
                  name="broadcast-wizard-sender-input"
                  isClearable={false}
                  isMulti={false}
                  forceCaret
                  label={t(
                    'broadcast:pages.broadcastWizard.steps.generalAndAudience.selectSender',
                  )}
                  isDisabled={noSenderOptionsAvailable}
                  canLoadMore={hasNextPage}
                  onLoadMore={loadMore}
                  isLoading={loadingSmsChannelResources}
                  hasError={Boolean(fieldState.error)}
                  onChange={(option) => {
                    if (option?.value) {
                      form.setValue('sender', option.value);
                    }
                  }}
                  value={selectedOption}
                />
              );
            }}
          />
        </GeneralInformationInputsContainer>
        {noSenderOptionsAvailable && (
          <WizardStepMessage
            title={t(
              'broadcast:pages.broadcastWizard.steps.generalAndAudience.noSendersAvailableMessage',
            )}
            type="info"
            data-lgg-id="broadcast-wizard-no-configured-sender-message"
          />
        )}
      </GeneralInformationContainer>
      <WizardStepSectionDivider />
      <AudienceSegmentationContainer>
        <WizardStepSectionTitle>
          {t(
            'broadcast:pages.broadcastWizard.steps.generalAndAudience.audienceSegmentation',
          )}
        </WizardStepSectionTitle>
        <WizardStepSectionDescription>
          {t(
            'broadcast:pages.broadcastWizard.steps.generalAndAudience.audienceSegmentationInfo',
          )}
        </WizardStepSectionDescription>
        <FiltersButton
          onClick={filtersVisibilityHandler.show}
          data-lgg-id="broadcast-wizard-contact-filters-button"
          type="button"
          variant="defaultWhite"
          icon="filters"
          size="regular"
          iconNotificationProps={{
            showNotification: filterCount > 0,
            notificationColor: 'cosmo',
          }}
        >
          <Trans
            i18nKey="broadcast:pages.broadcastWizard.steps.generalAndAudience.contactFiltersAndCount"
            components={{
              light: <FiltersButtonLightText />,
            }}
            count={filterCount}
          />
        </FiltersButton>
        <WizardStepSectionDivider />
        {/* TODO: ADD LOADING STATE */}
        {audienceSummary && !loadingAudienceSummary && (
          <AudienceSummaryContainer data-lgg-id="broadcast-wizard-audience-summary">
            <AudienceSummaryItem>
              <AudienceSummaryText>
                {t(
                  'broadcast:pages.broadcastWizard.steps.generalAndAudience.summary.contactsUnderCriteria',
                )}
              </AudienceSummaryText>
              <AudienceSummaryText data-lgg-id="broadcast-wizard-summary-contacts-matching">
                {audienceSummary.contactsMatchingCriteria}
              </AudienceSummaryText>
            </AudienceSummaryItem>
            <AudienceSummaryItem>
              <AudienceSummaryText>
                {t(
                  'broadcast:pages.broadcastWizard.steps.generalAndAudience.summary.contactsWithoutPhone',
                )}
              </AudienceSummaryText>
              <AudienceSummaryText data-lgg-id="broadcast-wizard-summary-contacts-without-phone">{`- ${audienceSummary.contactsWithoutPhoneNumber}`}</AudienceSummaryText>
            </AudienceSummaryItem>
            <AudienceSummaryItem>
              <AudienceSummaryText>
                {t(
                  'broadcast:pages.broadcastWizard.steps.generalAndAudience.summary.contactsWhoOptedOut',
                )}
              </AudienceSummaryText>
              <AudienceSummaryText
                className="result-divider"
                data-lgg-id="broadcast-wizard-summary-contacts-with-opt-out"
              >{`- ${audienceSummary.contactsWithOptOut}`}</AudienceSummaryText>
            </AudienceSummaryItem>
            <AudienceSummaryItem>
              <AudienceSummaryBoldText>
                {t(
                  'broadcast:pages.broadcastWizard.steps.generalAndAudience.summary.totalContacts',
                )}
              </AudienceSummaryBoldText>
              <AudienceSummaryBoldText data-lgg-id="broadcast-wizard-summary-total-contacts">
                {audienceSummary.contactsAvailable}
              </AudienceSummaryBoldText>
            </AudienceSummaryItem>
          </AudienceSummaryContainer>
        )}
      </AudienceSegmentationContainer>
      <ContactFilters
        visible={filtersVisibilityHandler.visible}
        onClose={filtersVisibilityHandler.close}
        submitHandlerOverride={(values) => {
          if (values) {
            setFilterCount(
              Object.values(values).filter((value) => !isEmpty(value)).length,
            );
            setFilterWhere(convertFormValuesToWhereInput(values, true));
          }
          filtersVisibilityHandler.close();
        }}
        onReset={() => {
          setFilterCount(0);
          setFilterWhere(undefined);
        }}
      />
    </FlexColumn>
  );
};
