import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import {
  ContactInteractionAttachmentFile,
  ContactInteractionAttachmentFileType,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Icon } from 'src/components/general/icon';
import { getFileSizeLabel } from 'src/components/pages/conversations/components/contact-interactions/items/helpers';
import { AttachmentPreviewModal } from 'src/components/pages/conversations/components/general/attachment-preview-modal';
import { useVisible } from 'src/hooks/use-visible';
import { downloadFile } from 'src/utils/download-file';

const FileAttachmentContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.koala};
  padding: 10px 20px 10px 15px;
  align-items: center;
  border-radius: 4px;
  margin: 0 5px 5px 0;
  max-width: 100%;

  ${up('md')} {
    background: ${({ theme }) => theme.colors.porcelain};
  }
`;

const AttachmentIcon = styled(Icon)`
  padding: 9px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};

  ${up('md')} {
    background: ${({ theme }) => theme.colors.koala};
  }

  svg path {
    fill: ${({ theme }) => theme.colors.flint};
  }
`;

const AttachmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  margin-left: 10px;
  width: max-content;
  max-width: calc(100% - 30px);
`;

const AttachmentInfoName = styled.p`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gogo};
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

const AttachmentInfoFileSize = styled.span`
  font-family: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.flint};
  font-size: 8px;
  line-height: 10px;
`;

type FileAttachmentProps = {
  attachment: ContactInteractionAttachmentFile;
  allowPreview?: boolean;
  allowDownload?: boolean;
};

export const FileAttachment = memo<FileAttachmentProps>(
  ({ attachment, allowPreview = true, allowDownload = true }) => {
    const { filename = '', length = 0, url, mime, originalFilename } = attachment;
    const {
      visible: isPreviewVisible,
      show: showPreview,
      close: closePreview,
    } = useVisible();
    const displayName = originalFilename ?? filename ?? undefined;
    const previewType: ContactInteractionAttachmentFileType | null = match(attachment)
      .with(
        {
          type: 'DOCUMENT',
        },
        ({ mime }) => {
          if (mime?.startsWith('image/')) {
            return 'IMAGE';
          }
          if (mime?.startsWith('video/')) {
            return 'VIDEO';
          }

          return null;
        },
      )
      .with(
        {
          type: 'IMAGE',
        },
        () => 'IMAGE' as ContactInteractionAttachmentFileType,
      )
      .with(
        {
          type: 'VIDEO',
        },
        () => 'VIDEO' as ContactInteractionAttachmentFileType,
      )
      .otherwise(() => null);
    const previewEnabled = allowPreview && previewType;

    return (
      <>
        <FileAttachmentContainer
          data-lgg-id="contact-interaction-file-attachment"
          className="file-attachment"
          onClick={async () => {
            if (previewEnabled) {
              showPreview();
            } else if (allowDownload) {
              await downloadFile({
                fileUrl: url,
                fileName: displayName,
              });
            }
          }}
        >
          <AttachmentIcon type="attachment" />
          <AttachmentInfo>
            <AttachmentInfoName>{displayName}</AttachmentInfoName>
            <AttachmentInfoFileSize data-lgg-id="attachment-file-size">
              {getFileSizeLabel(length ?? 0)}
            </AttachmentInfoFileSize>
          </AttachmentInfo>
        </FileAttachmentContainer>
        {previewEnabled && displayName && mime && (
          <AttachmentPreviewModal
            visible={isPreviewVisible}
            onClose={closePreview}
            type={previewType}
            url={url}
            name={displayName}
            mimeType={mime}
            testId="conversations-attachment-preview"
          />
        )}
      </>
    );
  },
);
