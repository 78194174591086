import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { $getRoot } from 'lexical';
import SMSCounter from 'sms-counter';
import styled from 'styled-components';
import {
  DropdownButtonIcon,
  LggSelectableOptionsDropdownButtonWithCustomTrigger,
} from 'src/components/general/button/dropdown-button';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Icon } from 'src/components/general/icon';
import { TextInput } from 'src/components/general/inputs/text-input';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastWizardStepProps } from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import {
  WizardStepMessage,
  WizardStepSectionTitle,
} from 'src/components/pages/broadcast/components/broadcast-wizard/shared';
import { SmsCounter } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/sms/sms-counter';
import { EmojiPickerPlugin } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/emoji-picker-plugin';
import {
  InsertContactPlaceholderPlugin,
  resolvePreviewWithContactPlaceholders,
} from 'src/components/pages/conversations/components/general/lexical-editor/plugins/insert-contact-placeholder-plugin';
import { useVisible } from 'src/hooks/use-visible';

const lexicalEditorConfig = {
  namespace: 'BroadcastMessageComposeEditor',
  theme: {
    placeholder: 'editor-placeholder',
    paragraph: 'editor-paragraph',
  },
  onError(error) {
    throw error;
  },
};

const EditorBottomBar = styled(FlexRow)`
  border-top: 1px solid rgba(152, 169, 188, 0.251);
  height: 32px;
  justify-content: space-between;
  padding: 8px 10px;
`;

const BaseInput = styled(FlexColumn)<{ hasError: boolean }>`
  border: 1px solid;
  border-color: ${({ theme, hasError }) =>
    hasError ? theme.colors.complementaryColor7 : 'rgba(152, 169, 188, 0.25)'};
  border-radius: 4px;
`;

const EditorContainer = styled(BaseInput)`
  margin-bottom: 15px;
  max-width: 448px;
  width: 100%;
`;

const TextInputContainer = styled.div`
  margin: 10px 12px;
  min-height: 173px;
  position: relative;

  .editor-input {
    max-height: 200px;
    outline: none;
  }

  .editor-paragraph {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;

const PlaceholderText = styled.span`
  color: ${({ theme }) => theme.colors.geyser};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  pointer-events: none;
  position: absolute;
  text-align: left;
  top: 0;
`;

const PluginsContainer = styled(FlexRow)`
  & > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const InputLabel = styled.span`
  color: ${({ theme }) => theme.colors.raven};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 1px;
  text-align: left;
`;

const MessagePreviewContainer = styled(FlexColumn)`
  width: 238px;
`;

const MessagePreview = styled.p`
  background: ${({ theme }) => theme.colors.porcelain};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 10px 0 0;
  max-height: 300px;
  padding: 15px;
  white-space: pre-wrap;
`;

const ComposeMessageStepContainer = styled(FlexRow)`
  justify-content: space-between;
`;

const UnsubscribeMessageInputContainer = styled(BaseInput)`
  align-items: center;
  flex-direction: row;
  height: 38px;
  margin-bottom: 5px;
  padding: 0 10px 0 9px;
`;

const OptOutLanguageSelectorButton = styled(
  LggSelectableOptionsDropdownButtonWithCustomTrigger,
)`
  &,
  &:hover,
  &:active {
    background: transparent;
    border: none;
    outline: none;
  }

  height: 20px;
  padding: 5px;
`;

const StyledUnsubscribeInput = styled(TextInput)`
  height: 20px;
  line-height: 16px;
  padding: 0;
  width: 100%;

  &,
  &:active,
  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

const StyledFlagIcon = styled(Icon)`
  margin-right: 0;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const MessageInputContainer = styled.div`
  position: relative;
`;

const LanguageSelectorContainer = styled(FlexRow)`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background: ${({ theme }) => theme.colors.porcelain};
  }
`;

const LanguageSelectorDropdownIcon = styled(DropdownButtonIcon)<{ $active: boolean }>`
  margin-left: 5px;
  transform: ${({ $active }) => ($active ? 'rotate(180deg) !important' : 'none')};

  svg {
    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

const FormContainer = styled(FlexColumn)`
  margin-right: 20px;
  max-width: 448px;
  width: 100%;
`;

const SmsCountMessageStrongText = styled.span`
  font-family: ${({ theme }) => theme.font.medium};
`;

enum UnsubscribeMessageLanguage {
  EN = 'EN',
  ES = 'ES',
}

export const MessageComposeStep = ({ form }: BroadcastWizardStepProps) => {
  const { t } = useTranslation(['common', 'broadcast']);
  const [smsCount, setSmsCount] = useState<number>(0);
  const [messagePreview, setMessagePreview] = useState<React.ReactElement | undefined>(
    undefined,
  );
  const optOutMessageOptionsVisibilityHandler = useVisible();

  useEffect(() => {
    const formSubscription = form.watch(({ message, optOutMessage }) => {
      setSmsCount(SMSCounter.count(`${message}${optOutMessage}`).messages ?? 0);

      setMessagePreview(
        message || optOutMessage ? (
          <>
            {message ? resolvePreviewWithContactPlaceholders(message) : null}
            {message && optOutMessage ? (
              <>
                <br />
                <br />
              </>
            ) : null}
            {optOutMessage ? optOutMessage : null}
          </>
        ) : undefined,
      );
    });

    return () => formSubscription.unsubscribe();
  }, [form]);

  return (
    <ComposeMessageStepContainer data-lgg-id="broadcast-wizard-step-compose-sms">
      <FormContainer>
        <InputLabel>
          {t('broadcast:pages.broadcastWizard.steps.smsCompose.fields.textMessage.title')}
        </InputLabel>
        <Controller
          control={form.control}
          name="message"
          rules={{
            required: true,
          }}
          key="broadcast-wizard-message-input"
          render={({ field, fieldState }) => {
            return (
              <EditorContainer hasError={Boolean(fieldState.error)}>
                <LexicalComposer initialConfig={lexicalEditorConfig}>
                  <TextInputContainer>
                    <PlainTextPlugin
                      contentEditable={
                        <MessageInputContainer data-lgg-id="broadcast-wizard-message-input">
                          <Scrollbar>
                            <ContentEditable className="editor-input" />
                          </Scrollbar>
                        </MessageInputContainer>
                      }
                      placeholder={
                        <PlaceholderText>
                          {t(
                            'broadcast:pages.broadcastWizard.steps.smsCompose.fields.textMessage.placeholder',
                          )}
                        </PlaceholderText>
                      }
                      ErrorBoundary={LexicalErrorBoundary}
                    />
                    <OnChangePlugin
                      onChange={(editorState) => {
                        editorState.read(() => {
                          const root = $getRoot();

                          form.setValue('message', root.getTextContent());

                          if (fieldState.error) {
                            form.clearErrors('message');
                          }
                        });
                      }}
                    />
                  </TextInputContainer>
                  <EditorBottomBar>
                    <PluginsContainer>
                      <EmojiPickerPlugin />
                      <InsertContactPlaceholderPlugin />
                    </PluginsContainer>
                    <SmsCounter message={field.value ?? ''} />
                  </EditorBottomBar>
                </LexicalComposer>
              </EditorContainer>
            );
          }}
        />
        <InputLabel>
          {t(
            'broadcast:pages.broadcastWizard.steps.smsCompose.fields.outOutMessage.title',
          )}
        </InputLabel>
        <Controller
          control={form.control}
          name="optOutMessage"
          rules={{
            required: true,
          }}
          render={({ formState }) => {
            return (
              <UnsubscribeMessageInputContainer
                hasError={Boolean(formState.errors.optOutMessage)}
              >
                <Controller
                  control={form.control}
                  name="optOutMessage"
                  rules={{
                    required: true,
                  }}
                  key="broadcast-wizard-opt-out-message-input"
                  render={({ field }) => {
                    return (
                      <StyledUnsubscribeInput
                        {...field}
                        data-lgg-id="broadcast-wizard-opt-out-message-input"
                        label=""
                        placeholder={t(
                          'broadcast:pages.broadcastWizard.steps.smsCompose.fields.outOutMessage.placeholder',
                        )}
                        autoComplete="off"
                        reserveErrorArea={false}
                        showErrorMessage={false}
                        value={field.value ?? ''}
                        containerStyles={{
                          width: '100%',
                        }}
                      />
                    );
                  }}
                />
                <Controller
                  control={form.control}
                  name="optOutMessageLanguage"
                  rules={{
                    required: true,
                  }}
                  key="broadcast-wizard-opt-out-message-language-input"
                  render={({ field }) => {
                    return (
                      <OptOutLanguageSelectorButton
                        onClick={optOutMessageOptionsVisibilityHandler.show}
                        size="small"
                        variant="default"
                        options={[
                          {
                            label: t('common:languages.english'),
                            value: UnsubscribeMessageLanguage.EN,
                            icon: <Icon type="flagUsa" />,
                            onClick: () => {
                              form.setValue(
                                'optOutMessageLanguage',
                                UnsubscribeMessageLanguage.EN,
                              );
                            },
                            'data-lgg-id': 'opt-out-message-language-option-en',
                          },
                          {
                            label: t('common:languages.spanish'),
                            value: UnsubscribeMessageLanguage.ES,
                            icon: <Icon type="flagSpain" />,
                            onClick: () => {
                              form.setValue(
                                'optOutMessageLanguage',
                                UnsubscribeMessageLanguage.ES,
                              );
                            },
                            'data-lgg-id': 'opt-out-message-language-option-es',
                          },
                        ]}
                        selectedValue={field.value ?? null}
                        visibilityHandler={optOutMessageOptionsVisibilityHandler}
                      >
                        <LanguageSelectorContainer data-lgg-id="broadcast-wizard-opt-out-message-language-dropdown">
                          <StyledFlagIcon
                            lggTestId="opt-out-language-icon"
                            type={
                              field.value === UnsubscribeMessageLanguage.EN
                                ? 'flagUsa'
                                : 'flagSpain'
                            }
                          />
                          <LanguageSelectorDropdownIcon
                            type="arrowdown"
                            $active={optOutMessageOptionsVisibilityHandler.visible}
                          />
                        </LanguageSelectorContainer>
                      </OptOutLanguageSelectorButton>
                    );
                  }}
                />
              </UnsubscribeMessageInputContainer>
            );
          }}
        />
        {smsCount ? (
          <WizardStepMessage
            title={
              <Trans
                i18nKey="broadcast:pages.broadcastWizard.steps.smsCompose.smsCountMessage"
                values={{ count: smsCount }}
                components={{ strong: <SmsCountMessageStrongText /> }}
              />
            }
            type="info"
            data-lgg-id="broadcast-wizard-sms-count-message"
          />
        ) : null}
      </FormContainer>
      {messagePreview ? (
        <MessagePreviewContainer>
          <WizardStepSectionTitle>
            {t('broadcast:pages.broadcastWizard.steps.smsCompose.messagePreview')}
          </WizardStepSectionTitle>
          <MessagePreview data-lgg-id="broadcast-wizard-message-preview">
            <Scrollbar>{messagePreview}</Scrollbar>
          </MessagePreview>
        </MessagePreviewContainer>
      ) : null}
    </ComposeMessageStepContainer>
  );
};
