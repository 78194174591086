import React, { memo, useCallback, useEffect } from 'react';
import { useService } from 'react-service-locator';
import notification from 'antd/lib/notification';
import {
  ErrorBoundary,
  globalErrorNotificationKey,
} from 'src/components/error-boundary/error-boundary';
import { NotificationProvider } from 'src/components/general/feedback/hooks/use-show-notification';
import { useAuth } from 'src/components/providers/auth-provider';
import { AuthenticatedRouter } from 'src/components/routing/authenticated-router/authenticated-router';
import { UnauthenticatedRouter } from 'src/components/routing/unauthenticated-router';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useHistoryChange } from 'src/hooks/use-history-change';
import { useI18n } from 'src/hooks/use-i18n';
import { AppStatusService } from 'src/services/app-status.service';

const useNewBuildVersionUpdate = () => {
  const { getFeatureFlag } = useAuthorization();
  const {
    state: { newVersionAvailable },
    currentBuild,
  } = useService(AppStatusService);

  //Force refresh if the client build date is older than the minimum required
  const minimumClientBuildDateRequired = getFeatureFlag(
    'config-minimum-client-build-date-required',
  );

  useEffect(() => {
    if (!newVersionAvailable || !minimumClientBuildDateRequired) {
      return;
    }

    if (new Date(currentBuild.date) < new Date(minimumClientBuildDateRequired)) {
      window.location.reload();
    }
  }, [newVersionAvailable, minimumClientBuildDateRequired, currentBuild]);

  const refreshPageOnNewVersion = useCallback(() => {
    if (newVersionAvailable) {
      window.location.reload();
    }
  }, [newVersionAvailable]);

  useHistoryChange(refreshPageOnNewVersion);
};

export const Landing = memo(() => {
  useNewBuildVersionUpdate();
  const { user } = useAuth(false);

  useI18n(user?.language);

  const closeGlobalErrorNotification = useCallback(() => {
    notification.close(globalErrorNotificationKey);
  }, []);

  useHistoryChange(closeGlobalErrorNotification);

  return (
    <ErrorBoundary>
      <NotificationProvider>
        {user ? <AuthenticatedRouter /> : <UnauthenticatedRouter />}
      </NotificationProvider>
    </ErrorBoundary>
  );
});
