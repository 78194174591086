import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { BroadcastWizardStep } from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import { WizardBaseContainer } from 'src/components/pages/broadcast/components/broadcast-wizard/shared';

const HintsContainer = styled(WizardBaseContainer)`
  min-width: 288px;
  padding: 20px;
  width: 288px;
`;

const HintIcon = styled(Icon)`
  align-items: end;
  background: rgba(45, 152, 218, 0.2);
  border-radius: 50%;
  display: flex;
  height: 38px;
  justify-content: end;
  margin-bottom: 19px;
  width: 38px;

  svg {
    bottom: -1px;
    height: 24px;
    left: 1px;
    position: relative;
    width: 24px;

    path {
      fill: ${({ theme }) => theme.colors.gogo};
    }
  }
`;

const HintTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 26px 0;
  text-align: left;
`;

const HintContainer = styled(FlexColumn)`
  margin-bottom: 30px;
`;

const HintSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 3px 0;
  text-align: left;
`;

const HintDescription = styled.p`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  margin: 0;
  text-align: left;
`;

type BroadcastWizardHint = {
  title: string;
  description: string;
};

type BroadcastWizardHintConfiguration = {
  icon: string;
  title: string;
  hints: BroadcastWizardHint[];
};

type BroadcastWizardHintsProps = {
  step: BroadcastWizardStep;
};

export const BroadcastWizardHints = ({ step }: BroadcastWizardHintsProps) => {
  const { t } = useTranslation(['broadcast']);

  const configuration: BroadcastWizardHintConfiguration = useMemo(() => {
    return match(step)
      .with(BroadcastWizardStep.AUDIENCE, () => ({
        icon: 'contactSetting',
        title: t('broadcast:pages.broadcastWizard.hints.audience.title'),
        hints: [
          {
            title: t('broadcast:pages.broadcastWizard.hints.audience.generalHint.title'),
            description: t(
              'broadcast:pages.broadcastWizard.hints.audience.generalHint.description',
            ),
          },
          {
            title: t('broadcast:pages.broadcastWizard.hints.audience.filterHint.title'),
            description: t(
              'broadcast:pages.broadcastWizard.hints.audience.filterHint.description',
            ),
          },
        ],
      }))
      .with(BroadcastWizardStep.MESSAGE_COMPOSE, () => ({
        icon: 'sms',
        title: t('broadcast:pages.broadcastWizard.hints.compose.title'),
        hints: [
          {
            title: t('broadcast:pages.broadcastWizard.hints.compose.crafting.title'),
            description: t(
              'broadcast:pages.broadcastWizard.hints.compose.crafting.description',
            ),
          },
          {
            title: t('broadcast:pages.broadcastWizard.hints.compose.lengthHint.title'),
            description: t(
              'broadcast:pages.broadcastWizard.hints.compose.lengthHint.description',
            ),
          },
        ],
      }))
      .with(BroadcastWizardStep.SCHEDULE, () => ({
        icon: '',
        title: '',
        hints: [],
      }))
      .with(BroadcastWizardStep.OVERVIEW, () => ({
        icon: '',
        title: '',
        hints: [],
      }))
      .exhaustive();
  }, [step, t]);

  const { icon, title, hints } = configuration;

  return (
    <HintsContainer data-lgg-id="broadcast-wizard-hints">
      <HintIcon type={icon} />
      <HintTitle>{title}</HintTitle>
      {hints.map(({ description, title }, index) => {
        const key = `wizard-hint-${index}`;

        return (
          <HintContainer data-lgg-id={key} key={key}>
            <HintSubtitle>{title}</HintSubtitle>
            <HintDescription>{description}</HintDescription>
          </HintContainer>
        );
      })}
    </HintsContainer>
  );
};
