import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  User,
  Contact,
  ContactInteractionUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { ContactLabel } from 'src/components/domain/contacts/contact-label';
import { ContactStatusElement } from 'src/components/domain/contacts/contact-status-element';
import { showContactStatusModal } from 'src/components/domain/contacts/contact-status-modal';
import { LggOptionsDropdownButtonWithCustomTriggerControlled } from 'src/components/general/button/dropdown-button';
import { AssigneeLabel } from 'src/components/general/display/assignee-label';
import { Table, TableColumns } from 'src/components/general/display/table';
import {
  getDefaultSortOrder,
  getSortDirectionByOrder,
  MoreOptionsIcon,
  SorterData,
  TableColumnTitle,
} from 'src/components/general/table-helpers';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';

const StyledTable = styled(Table)`
  margin: 0;
` as typeof Table;

type ContactsTableProps = {
  contacts: Contact[];
  loading: boolean;
  sortKey?: string;
  sortDirection?: string;
  onSort: (key: string, direction: SortDirection | undefined) => void;
};

export const ContactTable = memo(
  ({ contacts, loading, onSort, sortKey, sortDirection }: ContactsTableProps) => {
    const { formatSimpleRelativeDate } = useFormatDate();
    const { getFeatureFlag } = useAuthorization();
    const hasCrmFeatureFlag = getFeatureFlag('crm');
    const breakpointUpMd = useBreakpoint(up('md'));
    const {
      getLegacyContactFollowUpUrl,
      getContactModalUrl,
      getLegacyContactAssigneeModalUrl,
    } = useUrls();
    const { t } = useTranslation(['contacts', 'common']);

    const columns: TableColumns<Contact>[] = [];

    columns.push({
      key: 'contact',
      title: (
        <TableColumnTitle data-lgg-id="contact-table-column-header-title">
          {t('contacts:contactTableColumns.contact')}
        </TableColumnTitle>
      ),
      dataIndex: 'label',
      width: '100%',
      contentMaxWidth: '450px',
      contentMinWidth: '250px',
      render: (value, contact, index) => (
        <ContactLabel
          contact={contact}
          showBlockedBadge={true}
          testId="contact-table-row-cell-label"
          popoverPlacement={index === 0 ? 'bottomLeft' : 'topLeft'}
        />
      ),
    });

    if (hasCrmFeatureFlag) {
      columns.push(
        {
          key: 'interest',
          title: (
            <TableColumnTitle data-lgg-id="contact-table-column-header-interest">
              {t('contacts:contactTableColumns.interest')}
            </TableColumnTitle>
          ),
          dataIndex: 'interest',
          contentMinWidth: '150px',
          contentMaxWidth: '250px',
          sorter: true,
          defaultSortOrder: getDefaultSortOrder(sortKey === 'interest', sortDirection),
          render: (interest) => (
            <TableColumnTitle data-lgg-id="contact-table-row-cell-interest">
              {interest}
            </TableColumnTitle>
          ),
        },
        {
          key: 'lastContactInteraction',
          title: (
            <TableColumnTitle data-lgg-id="contact-table-column-header-last-interaction">
              {t('contacts:contactTableColumns.lastInteraction')}
            </TableColumnTitle>
          ),
          dataIndex: 'lastContactInteraction',
          contentMinWidth: '136px',
          contentMaxWidth: '136px',
          sorter: true,
          defaultSortOrder: getDefaultSortOrder(
            sortKey === 'lastContactInteraction',
            sortDirection,
          ),
          render: (lastInteraction: ContactInteractionUnion) => {
            const lastInteractionDate = lastInteraction?.occurredAt;

            return lastInteractionDate
              ? formatSimpleRelativeDate(lastInteractionDate, new Date())
              : t('common:never');
          },
        },
      );
    }

    columns.push({
      key: 'assignee',
      title: (
        <TableColumnTitle data-lgg-id="contact-table-column-header-assignee">
          {t('contacts:contactTableColumns.assignee')}
        </TableColumnTitle>
      ),
      dataIndex: 'assignee',
      contentMinWidth: '200px',
      contentMaxWidth: '300px',
      sorter: true,
      defaultSortOrder: getDefaultSortOrder(sortKey === 'assignee', sortDirection),
      render: (assignee: User, contact) => (
        <AssigneeLabel
          data-lgg-id="contact-table-row-cell-assignee"
          assignee={assignee}
          onClick={() => {
            openRemoteModalWrapper(getLegacyContactAssigneeModalUrl(contact.id));
          }}
        />
      ),
    });

    if (hasCrmFeatureFlag) {
      columns.push({
        key: 'status',
        title: (
          <TableColumnTitle data-lgg-id="contact-table-column-header-status">
            {t('contacts:contactTableColumns.status')}
          </TableColumnTitle>
        ),
        dataIndex: 'status',
        contentMaxWidth: '160px',
        contentMinWidth: '160px',
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'status', sortDirection),
        render: (_, contact) => (
          <ContactStatusElement
            stage={contact.stage}
            status={contact.status}
            contactId={contact.id}
          />
        ),
      });
    }

    columns.push({
      key: 'options',
      align: 'right',
      contentMaxWidth: '5px',
      contentMinWidth: '5px',
      wrapContent: true,
      render: (_, contact) => (
        <LggOptionsDropdownButtonWithCustomTriggerControlled
          customTrigger={
            <MoreOptionsIcon
              type="moreOptions"
              lggTestId="contact-table-row-cell-more-options"
            />
          }
          options={[
            {
              label: t('contacts:contactOptions.view'),
              icon: 'magnifyingGlass',
              'data-lgg-id': 'contact-table-view-contact-option',
              to: getContactModalUrl(contact.id),
            },

            ...(hasCrmFeatureFlag
              ? [
                  {
                    label: t('contacts:contactOptions.updateStatus'),
                    icon: 'status',
                    'data-lgg-id': 'contact-table-update-contact-status-option',
                    onClick: () => {
                      showContactStatusModal(contact.id);
                    },
                  },
                  {
                    label: t('contacts:contactOptions.followUp'),
                    icon: 'contactSetting',
                    'data-lgg-id': 'contact-table-follow-up-option',
                    onClick: () => {
                      openRemoteModalWrapper(getLegacyContactFollowUpUrl(contact.id));
                    },
                  },
                ]
              : []),
          ]}
        />
      ),
    });

    return (
      <>
        <StyledTable
          data-lgg-id="contacts-table"
          onChange={(pagination, filters, sorter) => {
            const { columnKey, order } = sorter as SorterData<Contact>;
            const isSorterActive = columnKey && order;

            onSort(isSorterActive ? columnKey : '', getSortDirectionByOrder(order));
          }}
          showSorterTooltip={false}
          loading={loading}
          columns={columns}
          dataSource={contacts}
          pagination={{
            position: [],
            pageSize: 50,
          }}
          rowClassName={(record, index) =>
            `contacts-table-row contact-row-user-${record.id} contact-row-position-${
              index + 1
            }`
          }
          tableLayout="auto"
          rowKey="id"
          showHeader={breakpointUpMd}
          scroll={!hasCrmFeatureFlag ? undefined : { x: 1200 }}
        />
      </>
    );
  },
);
