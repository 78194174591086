import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SMSCounter from 'sms-counter';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { PopoverV2 } from 'src/components/general/display/popover';
import { BottomDrawer } from 'src/components/general/drawer/bottom/bottom-drawer';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useVisible } from 'src/hooks/use-visible';

const Row = styled(FlexRow)`
  align-items: center;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  background-color: #dae0e7;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
`;

const CounterText = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-size: 11px;
  line-height: 14px;
  text-align: right;
`;

const CounterTextBold = styled(CounterText)`
  font-family: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.smalt};
`;

type SmsCounterProps = {
  message: string;
  attachmentCount?: number;
};

export const SmsCounter = ({ message, attachmentCount }: SmsCounterProps) => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { t } = useTranslation(['conversations']);
  const infoDetailsVisibilityHandler = useVisible();

  const messageData = SMSCounter.count(message);

  const content = useMemo(
    () => (
      <CounterText data-lgg-id="message-counter-text">
        {Boolean(attachmentCount) ? (
          <>
            {t('conversations:messageInput.options.sms.counter.sms_short')}
            <CounterTextBold data-lgg-id="message-counter-value">
              {messageData.messages}
            </CounterTextBold>
            {' | '}
            {t('conversations:messageInput.options.sms.counter.mms_short')}
            <CounterTextBold>{attachmentCount}</CounterTextBold>
          </>
        ) : (
          <>
            {t('conversations:messageInput.options.sms.counter.title')}
            <CounterTextBold data-lgg-id="message-counter-value">
              {messageData.messages}
            </CounterTextBold>
          </>
        )}
      </CounterText>
    ),
    [messageData.messages, t, attachmentCount],
  );

  return breakpointUpMd ? (
    <Row>
      <PopoverV2
        getPopupContainer={(a) => a}
        placement="bottomLeft"
        align={{
          offset: [0, -6],
        }}
        overlayClassName="sms-counter-popover"
        content={<PopoverContent messageData={messageData} />}
        trigger={['click']}
      >
        <Circle data-lgg-id="desktop-message-info">
          <Icon type="info" />
        </Circle>
      </PopoverV2>

      {content}
    </Row>
  ) : (
    <>
      <BottomDrawer
        visible={infoDetailsVisibilityHandler.visible}
        title={t('conversations:messageInput.options.sms.counter.popover.title')}
        onClose={infoDetailsVisibilityHandler.close}
      >
        <SMSDetails messageData={messageData} />
      </BottomDrawer>
      <Row>
        <Circle
          data-lgg-id="mobile-message-info"
          onClick={infoDetailsVisibilityHandler.show}
        >
          <Icon type="info" />
        </Circle>

        {content}
      </Row>
    </>
  );
};

const Container = styled.div`
  width: 360px;
  border: solid 1px ${({ theme }) => theme.colors.koala};
  border-radius: 6px;
`;
const TopSection = styled.div`
  padding: 20px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.koala};
  line-height: 16px !important;

  ${up('md')} {
    padding: 0 15px 15px 15px;
    line-height: 14px !important;
  }
`;

const Title = styled.span`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.colors.carbon};
  margin-bottom: 20px;
  display: block;
  line-height: normal;
  padding: 15px 15px 0 15px;
`;

const MidSection = styled.div`
  padding: 20px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.koala};

  ${up('md')} {
    padding: 15px;
  }
`;

const BottomSection = styled(FlexRow)`
  width: 100%;
  align-items: center;
  background-color: #f4f6f8;
`;

const SectionText = styled.span`
  font-size: 12px;
  text-align: left;
  color: ${({ theme }) => theme.colors.flint};
`;

const BoldText = styled(SectionText)`
  font-family: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.smalt};
`;

const MessageDetail = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0px 20px;

  ${up('md')} {
    padding-top: 5px;
    padding-bottom: 11px;
  }
`;

const InfoAmount = styled.span`
  font-size: 16px;
  line-height: 2;
  letter-spacing: -0.32px;
  text-align: center;
  color: ${({ theme }) => theme.colors.smalt};
`;

const InfoText = styled.span`
  width: 70%;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 10px;
  line-height: 1;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${({ theme }) => theme.colors.geyser};
`;

const SMSDetails = ({
  messageData,
}: {
  messageData: {
    encoding: 'GSM_7BIT' | 'UTF_16';
    length: number;
    messages: number;
    per_message: number;
    remaining: number;
  };
}) => {
  const { t } = useTranslation(['conversations']);

  return (
    <>
      <TopSection>
        <SectionText>
          <Trans
            i18nKey="conversations:messageInput.options.sms.counter.popover.details"
            components={{ strong: <BoldText />, break: <br /> }}
          />
        </SectionText>
      </TopSection>
      <MidSection>
        <SectionText>
          {t('conversations:messageInput.options.sms.counter.popover.footer')}
        </SectionText>
      </MidSection>
      <BottomSection>
        <MessageDetail>
          <InfoAmount data-lgg-id="total-messages-info">
            {messageData.messages}
          </InfoAmount>
          <InfoText>
            {t('conversations:messageInput.options.sms.counter.popover.messageSegments')}
          </InfoText>
        </MessageDetail>
        <MessageDetail>
          <InfoAmount data-lgg-id="message-encoding-info">
            {messageData.encoding === 'GSM_7BIT' ? '7 bit' : 'Unicode'}
          </InfoAmount>
          <InfoText>
            {t('conversations:messageInput.options.sms.counter.popover.encodingType')}
          </InfoText>
        </MessageDetail>
        <MessageDetail>
          <InfoAmount>{messageData.per_message}</InfoAmount>
          <InfoText>
            {t(
              'conversations:messageInput.options.sms.counter.popover.charactersPerSegment',
            )}
          </InfoText>
        </MessageDetail>
        <MessageDetail>
          <InfoAmount data-lgg-id="total-character-info">{messageData.length}</InfoAmount>
          <InfoText>
            {t('conversations:messageInput.options.sms.counter.popover.usedCharacters')}
          </InfoText>
        </MessageDetail>
      </BottomSection>
    </>
  );
};

const PopoverContent = ({
  messageData,
}: {
  messageData: {
    encoding: 'GSM_7BIT' | 'UTF_16';
    length: number;
    messages: number;
    per_message: number;
    remaining: number;
  };
}) => {
  const { t } = useTranslation(['conversations']);

  return (
    <Container>
      <Title>{t('conversations:messageInput.options.sms.counter.popover.title')}</Title>
      <SMSDetails messageData={messageData} />
    </Container>
  );
};
