import React, { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { match } from 'ts-pattern';
import * as zod from 'zod';
import {
  advancedDateFilterRelativeTypePattern,
  advancedDateFilterRelativeWithUnitPattern,
} from '@lgg/isomorphic/utils/match-advanced-date-input';
import { useCampaignListForSelect } from 'src/components/domain/campaign/hooks/use-campaign-list-for-select';
import { useContactSourceListForSelect } from 'src/components/domain/contacts/hooks/use-contact-source-list-for-select';
import { useContactStageStatusOptions } from 'src/components/domain/contacts/hooks/use-contact-stage-status-options';
import { CompanyUsersSelect } from 'src/components/domain/users/components/company-users-select';
import { useSetFormFieldValue } from 'src/components/filters/helpers';
import {
  EndDateFilter,
  ReactFilters,
  StartDateFilter,
} from 'src/components/filters/react-filters';
import { useFilterHelpers } from 'src/components/general/filter/use-filter-helpers';
import { AdvancedDatePicker } from 'src/components/general/inputs/custom-date-pickers/advanced-date-picker';
import { BirthdayPicker } from 'src/components/general/inputs/custom-date-pickers/birthday-picker';
import { Select, SelectOption } from 'src/components/general/inputs/select/select';
import { TextInput } from 'src/components/general/inputs/text-input';
import {
  StyledTableFiltersForm,
  TableLayoutFilterProps,
  useTableSubmitHandler,
} from 'src/components/general/table-helpers';
import { ContactTagSelect } from 'src/components/pages/conversations/components/contact-information/legacy-contact-tags-selector';
import { useAuthorization } from 'src/hooks/use-authorization';

const schema = zod.object({
  date_from: zod.string().nullish(),
  date_to: zod.string().nullish(),
  interest: zod.string().optional(),
  city: zod.string().optional(),
  contact: zod.string().optional(),
  assigned_unassigned: zod.number().nullish(),
  lead_stage: zod.array(zod.string()).nullish(),
  lead_status: zod.array(zod.string()).nullish(),
  last_interaction: zod.union([zod.number(), zod.literal('NEVER')]).nullish(),
  agent: zod.number().nullish(),
  campaign: zod.number().nullish(),
  tag: zod.array(zod.string()).nullish(),
  source: zod.number().nullish(),
  last_interaction_at: zod
    .object({
      type: zod
        .union([
          zod.literal('_isToday'),
          zod.literal('_isThisWeek'),
          zod.literal('_isThisMonth'),
          zod.literal('_isThisYear'),
          zod.literal('_isYesterday'),
          zod.literal('_isLastWeek'),
          zod.literal('_isLastMonth'),
          zod.literal('_isLastYear'),
          zod.literal('_notSet'),
          zod.literal('_isLastXDays'),
          zod.literal('_isLastXWeeks'),
          zod.literal('_isLastXMonths'),
          zod.literal('_isLastXYears'),
          zod.literal('_isOverXDays'),
          zod.literal('_isOverXWeeks'),
          zod.literal('_isOverXMonths'),
          zod.literal('_isOverXYears'),
          zod.literal('_isBetween'),
          zod.literal('_isExactDate'),
        ])
        .nullish(),
      time_unit: zod.number().nullish(),
      from: zod.string().nullish(),
      to: zod.string().nullish(),
    })
    .nullish(),
  birthday: zod
    .object({
      type: zod
        .union([
          zod.literal('_isToday'),
          zod.literal('_isTomorrow'),
          zod.literal('_isYesterday'),
          zod.literal('_isThisWeek'),
          zod.literal('_isThisMonth'),
          zod.literal('_isLastWeek'),
          zod.literal('_isLastMonth'),
          zod.literal('_isNextWeek'),
          zod.literal('_isNextMonth'),
          zod.literal('_isExactDay'),
          zod.literal('_isExactMonth'),
        ])
        .nullish(),
      month: zod.number().nullish(),
      day: zod.number().nullish(),
    })
    .nullish(),
  blocked: zod.number().nullish(),
});

export type ContactFiltersFormValues = zod.infer<typeof schema>;

export const defaultContactFilters: ContactFiltersFormValues = {
  interest: '',
  city: '',
  contact: '',
  assigned_unassigned: null,
  lead_stage: [],
  lead_status: [],
  date_from: null,
  date_to: null,
  last_interaction: null,
  agent: null,
  campaign: null,
  tag: [],
  source: null,
  last_interaction_at: null,
  birthday: null,
  blocked: null,
};

export const ContactFilters = memo<TableLayoutFilterProps<ContactFiltersFormValues>>(
  ({ visible, filters, onClose, submitHandlerOverride, onReset }) => {
    const { t } = useTranslation(['common', 'contacts']);
    const { getFeatureFlag } = useAuthorization();
    const hasCrmFeatureFlag = getFeatureFlag('crm');
    const isAdvancedLastInteractionFilterEnabled = getFeatureFlag(
      'temp_lggdev-1835_implementacion-de-filtro-de-fecha-relativo-para-last-contact-interaction',
    );
    const isBirthdayFilterEnabled = getFeatureFlag(
      'temp_lggdev-2027_birthday-contacts-filter',
    );
    const submitHandler = useTableSubmitHandler({ onClose });
    const {
      loading: loadingStateStatusOptions,
      stageOptions = [],
      statusGroupOptions = [],
    } = useContactStageStatusOptions();
    const history = useHistory();
    const location = useLocation();
    const { campaignOptions, loadingCampaignOptions } = useCampaignListForSelect();
    const { sourceOptions, loadingSourceOptions } = useContactSourceListForSelect();
    const { decodeFilterDate, encodeFilterEndDate, encodeStartFilterDate } =
      useFilterHelpers();

    const form = useForm<ContactFiltersFormValues>({
      resolver: zodResolver(schema),
      defaultValues: filters,
    });

    const { control, handleSubmit, setValue, reset } = form;

    const { setFormFieldValue } = useSetFormFieldValue<ContactFiltersFormValues>({
      defaultValues: defaultContactFilters,
      setValue,
    });

    useEffect(() => {
      if (filters) {
        reset(filters);
      }
    }, [filters, reset]);

    const lastInteractionOptions: SelectOption<number | 'NEVER'>[] = [
      {
        label: t('contacts:contactsFilters.fields.lastInteraction.options.never'),
        value: 'NEVER',
      },
      {
        label: t('contacts:contactsFilters.fields.lastInteraction.options.overOneDay'),
        value: 24,
      },
      {
        label: t('contacts:contactsFilters.fields.lastInteraction.options.overThreeDays'),
        value: 72,
      },
      {
        label: t('contacts:contactsFilters.fields.lastInteraction.options.overSevenDays'),
        value: 168,
      },
      {
        label: t(
          'contacts:contactsFilters.fields.lastInteraction.options.overFourteenDays',
        ),
        value: 336,
      },
    ];

    return (
      <ReactFilters
        form={form}
        onSave={() => {
          void handleSubmit(submitHandlerOverride ?? submitHandler)();
        }}
        onReset={() => {
          onClose();
          reset(defaultContactFilters);
          onReset?.();
          history.push(location.pathname);
        }}
        filters={filters}
        visible={visible}
        onClose={onClose}
        testId="contact-filters"
      >
        <StyledTableFiltersForm>
          <StartDateFilter
            name="date_from"
            data-lgg-id="contact-filters-field-date-from"
            label={t('contacts:contactsFilters.fields.dateFrom')}
          />
          <EndDateFilter
            name="date_to"
            data-lgg-id="contact-filters-field-date-to"
            label={t('contacts:contactsFilters.fields.dateTo')}
          />
          <Controller
            control={control}
            name="contact"
            render={({ field }) => (
              <TextInput
                data-lgg-id="contact-filters-field-contact"
                autoCapitalize="off"
                label={t('contacts:contactsFilters.fields.keywords')}
                reserveErrorArea={false}
                {...field}
              />
            )}
          />
          {hasCrmFeatureFlag && (
            <>
              <Controller
                control={control}
                name="lead_stage"
                render={({ field }) => {
                  const selectedOptions = stageOptions.filter((option) =>
                    field.value?.includes(option.value),
                  );

                  return (
                    <Select
                      name="contact-filters-field-stage"
                      isLoading={loadingStateStatusOptions}
                      isMulti
                      label={t('contacts:contactsFilters.fields.leadStage')}
                      value={selectedOptions}
                      placeholder={`-- ${t('common:selectMultiple')} --`}
                      onChange={(option) =>
                        setFormFieldValue(
                          field.name,
                          option?.map(({ value }) => value),
                        )
                      }
                      options={stageOptions}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="lead_status"
                render={({ field }) => {
                  const selectedOptions = statusGroupOptions
                    .flatMap((option) => option.options)
                    .filter((option) => field.value?.includes(option.value));

                  return (
                    <Select
                      data-lgg-id="contact-filters-field-status"
                      name="contact-filters-field-status"
                      isMulti
                      label={t('contacts:contactsFilters.fields.leadStatus')}
                      value={selectedOptions}
                      onChange={(option) => {
                        setFormFieldValue(
                          field.name,
                          option?.map(({ value }) => `${value}`),
                        );
                      }}
                      options={statusGroupOptions}
                    />
                  );
                }}
              />
            </>
          )}
          <Controller
            control={control}
            name="assigned_unassigned"
            render={({ field }) => {
              const options: SelectOption<number>[] = [
                {
                  label: t(
                    'contacts:contactsFilters.fields.assignedUnassigned.options.unassigned',
                  ),
                  value: 1,
                },
                {
                  label: t(
                    'contacts:contactsFilters.fields.assignedUnassigned.options.assigned',
                  ),
                  value: 0,
                },
              ];

              return (
                <Select
                  data-lgg-id="contact-filters-field-assigned-unassigned"
                  name="contact-filters-field-assigned-unassigned"
                  value={options.find(({ value }) => value === field.value) ?? null}
                  options={options}
                  placeholder={t('common:selectOne')}
                  isSearchable={false}
                  label={t('contacts:contactsFilters.fields.assignedUnassigned.label')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="agent"
            render={({ field }) => {
              return (
                <CompanyUsersSelect
                  name="contact-filters-field-agent"
                  placeholder={t('common:selectOne')}
                  label={t('contacts:contactsFilters.fields.assignee.label')}
                  selectedUsersIds={field.value ? [field.value] : []}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                  isMulti={false}
                />
              );
            }}
          />
          {hasCrmFeatureFlag && (
            <Controller
              control={control}
              name="interest"
              render={({ field }) => (
                <TextInput
                  data-lgg-id="contact-filters-field-interest"
                  autoCapitalize="off"
                  label={t('contacts:contactsFilters.fields.interest')}
                  reserveErrorArea={false}
                  {...field}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="tag"
            render={({ field }) => {
              return (
                <ContactTagSelect
                  isMulti
                  name="contact-filters-field-tag"
                  label={t('contacts:contactsFilters.fields.tags')}
                  selectedTagsIds={field.value?.map((tag) => parseInt(tag)) ?? []}
                  onChange={(option) => {
                    setFormFieldValue(
                      field.name,
                      option?.map(({ value }) => `${value}`),
                    );
                  }}
                />
              );
            }}
          />
          {hasCrmFeatureFlag && (
            <>
              {isAdvancedLastInteractionFilterEnabled ? (
                <AdvancedDatePicker
                  values={{
                    type: filters?.last_interaction_at?.type ?? null,
                    dateTo: filters?.last_interaction_at?.to
                      ? decodeFilterDate(filters.last_interaction_at.to)
                      : null,
                    dateFrom: filters?.last_interaction_at?.from
                      ? decodeFilterDate(filters?.last_interaction_at?.from)
                      : null,
                    unit: filters?.last_interaction_at?.time_unit ?? null,
                  }}
                  data-lgg-id="contact-filters-field-advanced-last-interaction"
                  resetInnerForm={!visible}
                  label={t('contacts:contactsFilters.fields.lastInteraction.label')}
                  emptyValueDescriptionOverride={t('common:never')}
                  onChange={(props) => {
                    setFormFieldValue('last_interaction_at.type', props.type);

                    match(props)
                      .with({ type: '_isExactDate' }, ({ dateFrom }) => {
                        if (dateFrom) {
                          setFormFieldValue(
                            'last_interaction_at.from',
                            encodeStartFilterDate(dateFrom),
                          );
                          setFormFieldValue('last_interaction_at.to', null);
                          setFormFieldValue('last_interaction_at.time_unit', null);
                        }
                      })
                      .with({ type: '_isBetween' }, ({ dateFrom, dateTo }) => {
                        if (dateFrom && dateTo) {
                          setFormFieldValue(
                            'last_interaction_at.from',
                            encodeStartFilterDate(dateFrom),
                          );
                          setFormFieldValue(
                            'last_interaction_at.to',
                            encodeFilterEndDate(dateTo),
                          );
                          setFormFieldValue('last_interaction_at.time_unit', null);
                        }
                      })
                      .with(
                        { type: advancedDateFilterRelativeWithUnitPattern },
                        ({ unit }) => {
                          setFormFieldValue('last_interaction_at.time_unit', unit);
                          setFormFieldValue('last_interaction_at.from', null);
                          setFormFieldValue('last_interaction_at.to', null);
                        },
                      )
                      .with({ type: null }, () => {
                        setFormFieldValue('last_interaction_at.from', null);
                        setFormFieldValue('last_interaction_at.to', null);
                        setFormFieldValue('last_interaction_at.time_unit', null);
                      })
                      .with({ type: advancedDateFilterRelativeTypePattern }, () => {})
                      .exhaustive();
                  }}
                />
              ) : (
                <Controller
                  control={control}
                  name="last_interaction"
                  render={({ field }) => {
                    const selectedOption =
                      lastInteractionOptions.filter(
                        ({ value: optionValue }) => optionValue === field.value,
                      ) ?? null;

                    return (
                      <Select<SelectOption<number | 'NEVER'>>
                        name="contact-filters-field-last-interaction"
                        value={selectedOption}
                        options={lastInteractionOptions}
                        isSearchable={false}
                        label={t('contacts:contactsFilters.fields.lastInteraction.label')}
                        onChange={(option) =>
                          setFormFieldValue(field.name, option?.value)
                        }
                      />
                    );
                  }}
                />
              )}
            </>
          )}
          {hasCrmFeatureFlag && isBirthdayFilterEnabled && (
            <BirthdayPicker
              label={t('common:birthdayFilter.label')}
              testId="contact-filters-field-birthday"
              values={{
                type: filters?.birthday?.type ?? null,
                exactMonth: filters?.birthday?.month ?? null,
                exactDay: filters?.birthday?.day ?? null,
              }}
              resetInnerForm={!visible}
              onChange={(props) => {
                setFormFieldValue('birthday.type', props.type);

                match(props)
                  .with({ type: '_isExactDay' }, ({ exactDay, exactMonth }) => {
                    if (exactDay && exactMonth) {
                      setFormFieldValue('birthday.day', exactDay);

                      setFormFieldValue('birthday.month', exactMonth);
                    }
                  })
                  .with({ type: '_isExactMonth' }, ({ exactMonth }) => {
                    if (exactMonth) {
                      setFormFieldValue('birthday.month', exactMonth);
                    }
                  })
                  .with({ type: null }, () => {
                    setFormFieldValue('birthday.day', null);
                    setFormFieldValue('birthday.month', null);
                  })
                  .otherwise(() => {});
              }}
            />
          )}
          <Controller
            control={control}
            name="campaign"
            render={({ field }) => {
              const selectedOption = field.value
                ? campaignOptions.find((option) => option.value === field.value)
                : null;

              return (
                <Select
                  name="contact-filters-field-campaign"
                  value={selectedOption}
                  options={campaignOptions}
                  isLoading={loadingCampaignOptions}
                  placeholder={t('common:selectOne')}
                  isSearchable
                  label={t('contacts:contactsFilters.fields.campaign.label')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="source"
            render={({ field }) => {
              const selectedOption = field.value
                ? sourceOptions.find((option) => option.value === field.value)
                : null;

              return (
                <Select
                  name="contact-filters-field-source"
                  value={selectedOption}
                  options={sourceOptions}
                  isLoading={loadingSourceOptions}
                  placeholder={t('common:selectOne')}
                  isSearchable
                  label={t('contacts:contactsFilters.fields.source.label')}
                  onChange={(option) => setFormFieldValue(field.name, option?.value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <TextInput
                data-lgg-id="contact-filters-field-city"
                autoCapitalize="off"
                label={t('contacts:contactsFilters.fields.city')}
                reserveErrorArea={false}
                {...field}
              />
            )}
          />
          {getFeatureFlag('block-contacts') && (
            <Controller
              control={control}
              name="blocked"
              render={({ field }) => {
                const options: SelectOption<number>[] = [
                  {
                    label: t('contacts:contactsFilters.fields.blocked.options.blocked'),
                    value: 1,
                  },
                  {
                    label: t('contacts:contactsFilters.fields.blocked.options.unblocked'),
                    value: 0,
                  },
                ];

                return (
                  <Select
                    data-lgg-id="contact-filters-field-blocked"
                    name="contact-filters-field-blocked"
                    value={options.find(({ value }) => value === field.value) ?? null}
                    options={options}
                    placeholder={t('common:selectOne')}
                    isSearchable={false}
                    label={t('contacts:contactsFilters.fields.blocked.label')}
                    onChange={(option) => setFormFieldValue(field.name, option?.value)}
                  />
                );
              }}
            />
          )}
        </StyledTableFiltersForm>
      </ReactFilters>
    );
  },
);
