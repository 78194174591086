import styled, { css } from 'styled-components';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';

export const WizardBaseContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
`;

export const WizardStepSectionTitle = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
`;

export const WizardStepSectionDescription = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  text-align: left;
`;

export const WizardStepSectionDivider = styled.span`
  background: ${({ theme }) => theme.colors.koala};
  height: 1px;
  width: 100%;
`;

const WizardStepMessageIcon = styled(Icon)`
  margin-right: 10px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

const WizardStepMessageText = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin: 0;
  text-align: left;
`;

const WizardStepMessageContainer = styled(FlexRow)<{ $type: 'info' | 'warning' }>`
  border-radius: 6px;
  margin-top: 10px;
  padding: 9px 23px 9px 15px;

  ${({ $type }) =>
    $type === 'warning'
      ? css`
          background: ${({ theme }) => theme.colors.secondaryGold10};
          border: 1px solid ${({ theme }) => theme.colors.secondaryGold};

          ${WizardStepMessageIcon} {
            svg path {
              fill: ${({ theme }) => theme.colors.secondaryGold};
            }
          }
        `
      : null}

  ${({ $type }) =>
    $type === 'info'
      ? css`
          background: ${({ theme }) => theme.colors.topaz10};
          border: 1px solid ${({ theme }) => theme.colors.topaz60};

          ${WizardStepMessageIcon} {
            svg path {
              fill: ${({ theme }) => theme.colors.topaz};
            }
          }
        `
      : null}
`;

type WizardStepMessageProps = {
  title: React.ReactElement | string;
  ['data-lgg-id']: string;
  type: 'info' | 'warning';
};

export const WizardStepMessage = ({
  title,
  type = 'info',
  ...rest
}: WizardStepMessageProps) => {
  return (
    <WizardStepMessageContainer {...rest} $type={type}>
      <WizardStepMessageIcon type="warningAlertIcon" />
      <WizardStepMessageText>{title}</WizardStepMessageText>
    </WizardStepMessageContainer>
  );
};
