import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import OneSignal from 'onesignal-cordova-plugin';
import styled from 'styled-components';
import { ReactComponent as NotificationsIllustration } from 'src/assets/images/illustration_notification.svg';
import { AlertModal } from 'src/components/general/modals/alert-modal';
import { NotFoundPage } from 'src/components/pages/errors/not-found-page';
import { InstagramAuthPage } from 'src/components/pages/instagram-channel/instagram-oauth.page';
// import { GqlSubscriptionTest } from 'src/components/pages/gql-subscriptions-test';
import { IndustrySwitch } from 'src/components/routing/authenticated-router/industry-switch';
import { CustomLocationState } from 'src/components/routing/lgg-switch';
import { useNotificationPermission } from 'src/hooks/push-notifications/use-notification-permission';
import { useOnesignalSetUserId } from 'src/hooks/push-notifications/use-onesignal-set-user-id';
import { useDefaultInstitution } from 'src/hooks/use-default-institution';
import { useInstitutionPathPrefix } from 'src/hooks/use-institution-path-prefix';
import { useVisible } from 'src/hooks/use-visible';

const useIndexPath = (): string => {
  const location = useLocation<CustomLocationState>();

  const defaultInstitution = useDefaultInstitution();
  const { prefix: defaultPath } = useInstitutionPathPrefix({
    ...defaultInstitution,
    id: Number(defaultInstitution.id),
  });
  const systemInstitutionId = 0;

  if (Number(defaultInstitution.id) === systemInstitutionId) {
    return `${import.meta.env.VITE_ADMIN_BACKEND_BASE_URL}/system`;
  }

  return defaultPath + location.search;
};

const StyledNotificationsIllustration = styled(NotificationsIllustration)`
  height: 150px;
  margin-top: 30px;
  width: 180px;
`;

export const SHOWED_NOTIFICATION_PERMISSION_MODAL = 'showedNotificationPermissionModal';

const PushNotificationOffModal = () => {
  const { permissionState } = useNotificationPermission();
  const { close, show, visible } = useVisible();
  const isNativePlatform = Capacitor.isNativePlatform();
  const displayedModal = useRef(false);
  const { t } = useTranslation(['notifications']);

  useEffect(() => {
    if (!isNativePlatform) {
      return;
    }

    const shouldDisplayModal = async () => {
      const showedNotificationPermissionModal = await Preferences.get({
        key: SHOWED_NOTIFICATION_PERMISSION_MODAL,
      });

      if (
        !showedNotificationPermissionModal.value &&
        isNativePlatform &&
        permissionState &&
        permissionState !== 'granted'
      ) {
        show();
        await Preferences.set({
          key: SHOWED_NOTIFICATION_PERMISSION_MODAL,
          value: 'true',
        });
        displayedModal.current = true;
      }
    };

    void shouldDisplayModal();
  }, [isNativePlatform, permissionState, show]);

  if (!isNativePlatform) {
    return null;
  }

  return (
    <AlertModal
      visible={visible}
      onClose={close}
      title={t('notifications:pushNotifications.alertModal.title')}
      message={t('notifications:pushNotifications.alertModal.message')}
      icon={<StyledNotificationsIllustration />}
      button={{
        text: t('notifications:pushNotifications.alertModal.action'),
        onClick: () => {
          close();
          OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
            console.debug('User accepted notifications: ' + accepted);
          });
        },
      }}
    />
  );
};

export const AuthenticatedRouter = memo(() => {
  const defaultPath = useIndexPath();
  const location = useLocation<CustomLocationState>();
  const effectiveLocation = location.state?.background || location;
  useOnesignalSetUserId();

  return (
    <>
      <Switch location={effectiveLocation}>
        {/* https://github.com/remix-run/react-router/issues/4841 */}
        <Redirect
          exact
          strict
          from="(.*//+.*)"
          to={
            window.location.pathname.replace(/\/\/+/g, '/') +
            window.location.search +
            window.location.hash
          }
        />
        {/* {import.meta.env.MODE === 'development' ? (
        <Route path="/gql-test">
          <GqlSubscriptionTest />
        </Route>
      ) : null} */}
        <Route path="/instagram/auth">
          <InstagramAuthPage />
        </Route>
        <Route path="/account/login">
          {/* in case someone has this bookmarked, and they're already logged in */}
          <Redirect to="/" />
        </Route>
        <Route
          path="/"
          exact
          render={() => {
            if (defaultPath?.startsWith('http')) {
              window.location.replace(defaultPath);
            } else {
              return <Redirect to={defaultPath} />;
            }
          }}
        />
        <Route path="/i/:institutionType/:institutionId">
          <IndustrySwitch />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
      <PushNotificationOffModal />
    </>
  );
});
