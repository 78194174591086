import { Inject, Service, StatefulService } from 'react-service-locator';
import { Capacitor } from '@capacitor/core';
import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { ApiService } from 'src/services/http/api.service';
import { SuspenseService } from 'src/services/suspense.service';
import { AuthenticationError } from 'src/utils/errors/authentication-error';
import {
  LOCAL_STORAGE_KEY_API_TOKEN,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'src/utils/local-storage';

export type SessionData = {
  user: User;
};

type SessionServiceState = {
  sessionData: SessionData | null;
};

@Service()
export class SessionService extends StatefulService<SessionServiceState> {
  @Inject(ApiService)
  private readonly apiService!: ApiService;

  @Inject(SuspenseService)
  private readonly suspenseService!: SuspenseService;

  public readonly initializationPromise: Promise<void>;
  private resolveInitialization: () => void;

  constructor() {
    super({ sessionData: null });
    this.initializationPromise = new Promise<void>((resolve) => {
      this.resolveInitialization = resolve;
    });
  }

  suspendAndInitialize = (fn) => {
    this.suspenseService.suspendWith(() => {
      return fn()
        .then(({ data }) => {
          this.setState({ sessionData: data.me });
        })
        .catch((_) => this.setState({ sessionData: null }))
        .finally(() => {
          this.resolveInitialization();
        });
    });
  };

  get data() {
    if (!this.state.sessionData) {
      throw new Error('No session data');
    }
    return this.state.sessionData;
  }

  login = async (
    username: string,
    password: string,
    options: { staySignedIn: boolean },
  ) => {
    const { staySignedIn } = options;
    const response = await this.apiService.post('/auth/login', {
      username,
      password,
      staySignedIn,
    });

    if (Capacitor.isNativePlatform()) {
      setLocalStorageItem(LOCAL_STORAGE_KEY_API_TOKEN, response.data.token);
    } else {
      removeLocalStorageItem(LOCAL_STORAGE_KEY_API_TOKEN);
    }
  };

  logout = async () => {
    if (this.state.sessionData) {
      try {
        await this.apiService.get('/session/logout');
        removeLocalStorageItem(LOCAL_STORAGE_KEY_API_TOKEN);
      } catch (error) {
        if (!(error instanceof AuthenticationError)) {
          throw error;
        }
      }
    }

    window.location.reload();
  };
}
