import copy from 'copy-to-clipboard';

export const copyTextToClipboard = (text: string) => {
  copy(text);
};

export const copyUrlToClipboard = (url: string) => {
  const urlHost = import.meta.env.VITE_USER_WEB_APP_BASE_URL;

  copy(`${urlHost}${new URL(url).pathname}`);
};
