import { Institution } from '@lgg/isomorphic/types/__generated__/graphql';
import { useAuth } from 'src/components/providers/auth-provider';

export function useDefaultInstitution(): Institution {
  const { user } = useAuth();

  const institution = user.institution;

  if (!institution) {
    throw new Error('Could not obtain an institution');
  }

  // From the API a row with `0` as primary key, can not be populated, that's why we are
  // mocking the `0` institution, which is `System` type.
  if (Number(institution.id) === 0) {
    return {
      ...institution,
      type: 'System',
    };
  }

  return institution;
}
