import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import c from 'classnames';
import { $getSelection } from 'lexical';
import styled from 'styled-components';
import { PopoverV2 } from 'src/components/general/display/popover';
import { Icon } from 'src/components/general/icon';
import { LggEmojiPicker } from 'src/components/pages/conversations/components/contact-interactions/lgg-emoji-picker';
import { useVisible } from 'src/hooks/use-visible';

const EmojiPickerIcon = styled(Icon)`
  &.active,
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.smalt};
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const EmojiPickerPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const { visible: isEmojiPickerVisible, setVisible: setEmojiPickerVisibility } =
    useVisible();

  const insertEmoji = (emoji: string) => {
    editor.focus();

    editor.update(() => {
      const selection = $getSelection();

      if (selection) {
        selection.insertText(emoji);
      }
    });
  };

  return (
    <PopoverV2
      content={
        <LggEmojiPicker
          onEmojiClick={({ emoji }) => {
            insertEmoji(emoji);
            setEmojiPickerVisibility(false);
          }}
        />
      }
      hideArrow
      destroyTooltipOnHide
      placement="topLeft"
      align={{ offset: [-19, -9] }}
      trigger={['click']}
      visible={isEmojiPickerVisible}
      onVisibleChange={(visible) => {
        setEmojiPickerVisibility(visible);
      }}
    >
      <EmojiPickerIcon
        type="emoji"
        lggTestId="input-area-emoji-picker-trigger"
        className={c({ active: isEmojiPickerVisible })}
        onClick={() => {
          setEmojiPickerVisibility(!isEmojiPickerVisible);
        }}
      />
    </PopoverV2>
  );
};
